/*-----------------------------------------------------------------------------------
    Template Name: Extbot - App Landing Bootstrap 5 Template
    Version: 1.0
-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    01. Template default CSS
    02. Header
    03. Hero
    04. Navigation
    05. Features
    06. Partner
    07. Banner
    08. Pricing
    09. Testimonial
    10. Footer
    11. Animation
    12. Intro
    13. Screen
    14. Newsletter
    15. Trial
    16. FAQ
    17. Breadcrumb
    18. Blog
    19. Form
    20. Contact
    21. 404

-----------------------------------------------------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Common Style --*/
html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  color: #4a4b51;
}

a {
  text-decoration: none;
}
a:hover {
  color: #407ff0;
}

a,
button,
.btn,
img,
input {
  transition: all 0.3s ease 0s;
}

a:focus,
button:focus,
.btn:focus,
input:focus,
select:focus,
textarea:focus,
.swiper-slide:focus,
.swiper-button-next:focus,
.swiper-button-prev:focus {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
}

button {
  outline: none !important;
  border: 0 !important;
}
button:focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gordita";
  color: #032659;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

.img-full {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
}

::selection {
  background-color: #407ff0;
  color: #ffffff;
}

/* ---Custom Container--- */
@media (min-width: 1400px) {
  .container {
    --bs-gutter-x: 15px;
    max-width: 1200px;
  }
  .container .row {
    --bs-gutter-x: 30px;
  }
}

/* ---Playfair Font--- */
.playfair-font {
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

/* ---HTML Text Color--- */
.heading-color-2 * {
  color: #2f2b54;
}

/* ---Data Background Image--- */
[data-bg-image] {
  background-repeat: no-repeat;
  background-color: #ffffff;
  background-size: cover;
}

/* ---Additional Margin | Padding--- */
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

/* ---Section Title--- */
.section-area {
  text-align: center;
}
.section-title {
  font-size: 70px;
  line-height: 100px;
}
@media (max-width: 991px) {
  .section-title {
    font-size: 50px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .section-title {
    font-size: 35px;
  }
}
.section-desc {
  color: #57617b;
  font-size: 25px;
  line-height: 42px;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
}
@media (max-width: 991px) {
  .section-desc {
    font-size: 22px;
    line-height: 1.5;
  }
}
@media (max-width: 767px) {
  .section-desc {
    font-size: 20px;
  }
}
.section-style-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 575px) {
  .section-style-2 {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .section-style-2 .section-title {
    margin-bottom: 20px;
  }
}
.section-style-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
@media (max-width: 767px) {
  .section-style-3 {
    flex-direction: column;
    margin-bottom: 50px;
    text-align: center;
  }
}
.section-style-3 .section-title {
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 991px) {
  .section-style-3 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.section-style-3 .section-desc {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .section-style-3 .section-desc {
    max-width: 440px;
    font-size: 20px;
    margin-left: 0;
  }
}
.section-style-3 .section-wrap {
  margin-bottom: 90px;
}
@media (max-width: 767px) {
  .section-style-3 .section-wrap {
    margin-bottom: 25px;
  }
}

/* ---Custom Button Size--- */
.btn {
  border: 0;
}
.btn-custom-size {
  background-color: #407ff0;
  font-family: "Gordita";
  border-radius: 25px;
  color: #ffffff;
  padding: 0;
  width: 210px;
  height: 70px;
  line-height: 67px;
}
.btn-custom-size.roboto-font {
  font-family: "Roboto", sans-serif;
}
.btn-md {
  width: 150px;
  height: 65px;
  line-height: 62px;
}
.btn-lg {
  width: 230px;
  height: 70px;
  line-height: 67px;
  font-size: 19px;
}
.btn.sapphire-color.btn {
  background-color: #042659;
  color: #ffffff;
}
.btn.sapphire-color.btn i {
  transition: all 0.3s ease 0s;
  color: #f9b047;
  font-size: 20px;
}
.btn.paua-color {
  background-color: #2f2b54;
  color: #ffffff;
}
.btn.paua-color i {
  transition: all 0.3s ease 0s;
  color: #fe6a21;
  font-size: 20px;
}
.btn.blackcurrant-color {
  background-color: #1f1927;
  color: #ffffff;
}
.btn.blackberry-color {
  background-color: #500836;
  color: #ffffff;
}
.btn.blue-color {
  background-color: #1a2c99;
  color: #ffffff;
}
.btn.extbot-primary-2 {
  background-color: #fe6a21;
}
.btn.extbot-primary-3 {
  background-color: #25b760;
}
.btn.extbot-primary-4 {
  background-color: #ff8144;
}
.btn.extbot-primary-5 {
  background-color: #f0582e;
  color: #ffffff;
}
.btn.extbot-primary-hover:hover {
  background-color: #407ff0;
  color: #ffffff;
}
.btn.extbot-primary-hover:hover i {
  color: #ffffff;
}
.btn.extbot-primary-hover-2:hover {
  background-color: #fe6a21;
  color: #ffffff;
}
.btn.extbot-primary-hover-2:hover i {
  color: #ffffff;
}
.btn.extbot-primary-hover-3:hover {
  background-color: #25b760;
  color: #ffffff;
}
.btn.extbot-primary-hover-3:hover i {
  color: #ffffff;
}
.btn.extbot-primary-hover-4:hover {
  background-color: #ff8144;
  color: #ffffff;
}
.btn.extbot-primary-hover-4:hover i {
  color: #ffffff;
}
.btn.sapphire-hover:hover {
  background-color: #042659;
  color: #ffffff;
}
.btn.blackcurrant-hover:hover {
  background-color: #1f1927;
  color: #ffffff;
}
.btn.blue-hover:hover {
  background-color: #1a2c99;
  color: #ffffff;
}
.btn-outline {
  background-color: rgb(245, 248, 251);
  border: 2px solid rgb(227, 232, 241);
  border-radius: 25px;
  color: #042659;
  font-weight: 500;
  font-size: 19px;
}

/* ---Pagination--- */
.pagination {
  justify-content: center;
}
.pagination-area {
  margin-top: 75px;
}
.pagination li:not(:last-child) {
  margin-right: 20px;
}
.pagination .page-link {
  background-color: #f5f4fb;
  font-family: "Gordita";
  border-color: #f5f4fb;
  border-radius: 15px;
  padding: 0;
  width: 58px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  color: #07135b;
  font-weight: 500;
}
.pagination .page-link:hover {
  background-color: #5138ee;
  color: #ffffff;
}
.pagination .page-link:focus {
  outline: none;
  box-shadow: none;
}
.pagination .page-item:first-child .page-link, .pagination .page-item:last-child .page-link {
  border-radius: 15px;
}
.pagination .page-item.active .page-link {
  background-color: #5138ee;
  color: #ffffff;
}

/* ---Global Overlay--- */
.global-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease 0s;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  visibility: hidden;
}
.global-overlay.overlay-open {
  visibility: visible;
  cursor: pointer;
}

/*-- Scroll To Top --*/
.scroll-to-top {
  background-color: black;
  border-radius: 4px;
  color: #ffffff;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 999;
  overflow: hidden;
  display: block;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  animation: fadeOutDown 1s normal;
}
.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  animation: fadeInUp 1s normal;
}
.scroll-to-top i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  transition: all 0.3s ease 0s;
  color: #ffffff;
  display: inline-block;
}
.scroll-to-top.style-1:hover {
  background-color: #407ff0;
}
.scroll-to-top.style-2:hover {
  background-color: #fe6a21;
}
.scroll-to-top.style-3:hover {
  background-color: #25b760;
}
.scroll-to-top.style-4:hover {
  background-color: #ff8144;
}
.scroll-to-top.style-5:hover {
  background-color: #f0582e;
}

/*----------------------------------------*/
/*  02. Header
/*----------------------------------------*/
@media (max-width: 991px) {
  .header-area {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (min-width: 1400px) {
  .header-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .header-container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .header-position-absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
  }
}
.header-logo {
  display: block;
}
.header-menu-nav > ul > li {
  display: inline-block;
}
.header-menu-nav > ul > li:not(:last-child) {
  margin-right: 65px;
}
@media (max-width: 1399px) {
  .header-menu-nav > ul > li:not(:last-child) {
    margin-right: 35px;
  }
}
@media (max-width: 1199px) {
  .header-menu-nav > ul > li:not(:last-child) {
    margin-right: 30px;
  }
}
.header-menu-nav > ul > li > a {
  padding-top: 55px;
  padding-bottom: 55px;
}
.header-menu-nav > ul li a {
  color: #3b4666;
  display: block;
  font-size: 18px;
}
.header-menu-nav.white-text * {
  color: #ffffff;
}
.header-drop-holder {
  position: relative;
}
.header-drop-holder:hover .header-drop-menu {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1);
}
.header-drop-menu {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 0;
  transition: all 0.3s ease 0s;
  width: 250px;
  padding-left: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: left;
  z-index: 3;
  transform-origin: 0 0 0;
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
}
.header-drop-menu > li:not(:last-child) {
  padding-bottom: 10px;
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header-right li {
  display: inline-block;
}
.header-right li:not(:first-child) {
  margin-left: 28px;
}
@media (max-width: 767px) {
  .header-right li:not(:first-child) {
    margin-left: 15px;
  }
}
.header-right li a {
  font-weight: 500;
}
@media (min-width: 1200px) {
  .header-right li a {
    font-size: 18px;
  }
}
@media (max-width: 1399px) {
  .header-right li a {
    font-size: 15px;
  }
}
@media (max-width: 767px) {
  .header-right li i {
    font-size: 25px;
    color: #212529;
  }
}
.header-right li button {
  outline: none;
  box-shadow: none;
  border: 0;
}
@media (max-width: 1399px) {
  .header-right li .btn-custom-size {
    width: 130px;
    height: 45px;
    line-height: 42px;
  }
}
.header-sticky.is-active {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header-sticky.is-active .header-menu-nav > ul > li > a {
  padding-top: 35px;
  padding-bottom: 35px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .header-style-1 .header-logo {
    max-width: 60%;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .header-style-1 .header-menu {
    text-align: center;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .header-style-1 .header-menu {
    text-align: center;
  }
}
.header-style-1 .header-menu-nav ul li:hover > a {
  color: #407ff0;
}
.header-style-1 .header-drop-menu a:hover {
  color: #407ff0;
}
@media (min-width: 1200px) {
  .header-style-2 .header-menu-nav > ul > li:not(:last-child) {
    margin-right: 75px;
  }
}
.header-style-2 .header-with-btn {
  display: flex;
  justify-content: flex-end;
}
.header-style-2 .header-with-btn .header-right {
  margin-left: 115px;
}
@media (max-width: 1399px) {
  .header-style-2 .header-with-btn .header-right {
    margin-left: 75px;
  }
}
.header-style-2:not([class*=is-active]) .header-menu-nav > ul > li > a {
  color: #ffffff;
}
.header-style-2:not([class*=is-active]) .header-drop-menu a:hover {
  color: #fe6a21;
}
@media (min-width: 992px) {
  .header-style-2:not([class*=is-active]) .header-right li a {
    color: #ffffff;
  }
}
.header-style-2:not([class*=is-active]) .header-logo img.sticky-img {
  display: none;
}
@media (max-width: 991px) {
  .header-style-2:not([class*=is-active]) .header-logo img.sticky-img {
    display: block;
  }
  .header-style-2:not([class*=is-active]) .header-logo img.primary-img {
    display: none;
  }
}
.header-style-2[class*=is-active] .header-menu-nav > ul > li:hover > a {
  color: #fe6a21;
}
.header-style-2[class*=is-active] .header-menu-nav > ul > li .header-drop-menu li a:hover {
  color: #fe6a21;
}
.header-style-2[class*=is-active] .header-menu-nav.onepage-nav ul li.active a {
  color: #fe6a21;
}
.header-style-2[class*=is-active] .header-logo img.primary-img {
  display: none;
}
.header-style-2[class*=is-active] .header-logo img.sticky-img {
  display: block;
}
.header-style-3:not([class*=is-active]) .header-menu-nav > ul > li > a {
  color: #ffffff;
}
.header-style-3:not([class*=is-active]) .header-drop-menu a:hover {
  color: #25b760;
}
@media (min-width: 992px) {
  .header-style-3:not([class*=is-active]) .header-right li a {
    color: #ffffff;
  }
}
.header-style-3:not([class*=is-active]) .header-logo img.sticky-img {
  display: none;
}
@media (max-width: 991px) {
  .header-style-3:not([class*=is-active]) .header-logo img.sticky-img {
    display: block;
  }
  .header-style-3:not([class*=is-active]) .header-logo img.primary-img {
    display: none;
  }
}
.header-style-3[class*=is-active] .header-menu-nav > ul > li:hover > a {
  color: #25b760;
}
.header-style-3[class*=is-active] .header-menu-nav > ul > li .header-drop-menu li a:hover {
  color: #25b760;
}
.header-style-3[class*=is-active] .header-menu-nav.onepage-nav ul li.active a {
  color: #25b760;
}
.header-style-3[class*=is-active] .header-logo img.primary-img {
  display: none;
}
.header-style-3[class*=is-active] .header-logo img.sticky-img {
  display: block;
}
.header-style-4 * {
  font-family: "Gordita";
}
.header-style-4:not([class*=is-active]) .header-menu-nav > ul > li > a {
  color: #500836;
}
.header-style-4:not([class*=is-active]) .header-drop-menu a {
  font-size: 16px;
}
.header-style-4:not([class*=is-active]) .header-drop-menu a:hover {
  color: #ff8144;
}
@media (min-width: 992px) {
  .header-style-4:not([class*=is-active]) .header-right li a {
    color: #ffffff;
  }
}
.header-style-4[class*=is-active] .header-menu-nav > ul > li:hover > a {
  color: #ff8144;
}
.header-style-4[class*=is-active] .header-menu-nav > ul > li .header-drop-menu li a:hover {
  color: #ff8144;
}
.header-style-4[class*=is-active] .header-menu-nav.onepage-nav ul li.active a {
  color: #ff8144;
}
.header-style-4[class*=is-active] .header-drop-menu li a {
  font-size: 16px;
}
.header-style-5:not([class*=is-active]) .header-menu-nav > ul > li > a {
  color: #ffffff;
}
.header-style-5:not([class*=is-active]) .header-menu-nav > ul > li > a:hover {
  color: #f0582e;
}
.header-style-5:not([class*=is-active]) .header-menu-nav > ul > li.active a {
  color: #f0582e;
}
.header-style-5:not([class*=is-active]) .header-drop-menu a:hover {
  color: #f0582e;
}
@media (min-width: 768px) {
  .header-style-5:not([class*=is-active]) .header-right li a {
    color: #ffffff;
  }
}
.header-style-5:not([class*=is-active]) .header-logo img.sticky-img {
  display: none;
}
@media (max-width: 991px) {
  .header-style-5:not([class*=is-active]) .header-logo img.sticky-img {
    display: block;
  }
  .header-style-5:not([class*=is-active]) .header-logo img.primary-img {
    display: none;
  }
}
.header-style-5[class*=is-active] .header-menu-nav > ul > li:hover > a {
  color: #f0582e;
}
.header-style-5[class*=is-active] .header-menu-nav > ul > li .header-drop-menu li a:hover {
  color: #f0582e;
}
.header-style-5[class*=is-active] .header-menu-nav.onepage-nav ul li.active a {
  color: #f0582e;
}
.header-style-5[class*=is-active] .header-logo img.primary-img {
  display: none;
}
.header-style-5[class*=is-active] .header-logo img.sticky-img {
  display: block;
}
.header-area:not([class*=is-active]) .header-drop-menu {
  top: calc(100% - 25px);
}

/* ---Onepage Nav--- */
.onepage-nav > ul li.active a {
  color: #407ff0;
}
.onepage-offcanvas-nav > ul > li:not(:last-child) {
  border-bottom: 1px solid #eaeff4;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.onepage-offcanvas-nav > ul > li a {
  color: #3b4666;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.onepage-offcanvas-nav > ul > li.active a {
  color: #407ff0;
}
.onepage-offcanvas-nav > ul li .dropdown .btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: 0;
  outline: 0;
  box-shadow: none;
  font-size: 18px;
  transition: all 0.3s ease 0s;
}
.onepage-offcanvas-nav > ul li .dropdown .btn:after {
  content: none;
}
.onepage-offcanvas-nav > ul li .dropdown .btn.show i:before {
  content: "\eaa1";
}
.onepage-offcanvas-nav > ul li .dropdown-menu {
  position: relative !important;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 0;
  border: 0;
  transform: none !important;
  transition: all 0.3s ease 0s;
}
.onepage-offcanvas-nav > ul li .dropdown-menu li:not(:last-child) {
  padding-bottom: 10px;
}
.onepage-offcanvas-nav > ul li .dropdown-menu li a.dropdown-item {
  padding: 0;
}
.onepage-offcanvas-nav > ul li .dropdown-menu li a.dropdown-item:hover {
  background-color: transparent;
  color: #407ff0;
}

/* ---Setting Button--- */
.setting-body {
  padding-top: 15px;
  display: none;
}
.setting-item {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  justify-content: center;
}

/*----------------------------------------*/
/*  03. Hero
/*----------------------------------------*/
.hero-style-1.hero-bg {
  height: 1070px;
}
@media (max-width: 1399px) {
  .hero-style-1.hero-bg {
    height: 950px;
  }
}
@media (max-width: 1199px) {
  .hero-style-1.hero-bg {
    height: 850px;
  }
}
@media (max-width: 991px) {
  .hero-style-1.hero-bg {
    height: auto;
  }
}
.hero-style-1 .hero-item {
  display: flex;
  align-items: center;
  height: 100%;
}
@media (max-width: 991px) {
  .hero-style-1 .hero-item {
    flex-direction: column;
  }
}
.hero-style-1 .hero-item .with-sticker {
  display: flex;
}
.hero-style-1 .hero-item .with-sticker .single-img {
  box-shadow: 0 8px 20px 0 rgba(139, 245, 255, 0.1);
  border-radius: 35px;
  margin-left: -100px;
  position: relative;
  bottom: -40px;
}
@media (max-width: 479px) {
  .hero-style-1 .hero-item .with-sticker .single-img {
    margin-left: -70px;
    bottom: -20px;
  }
}
.hero-style-1 .hero-img-wrap {
  padding-left: 30px;
  display: flex;
  width: 50%;
}
@media (max-width: 991px) {
  .hero-style-1 .hero-img-wrap {
    padding-top: 30px;
    padding-right: 30px;
    width: 100%;
  }
}
.hero-style-1 .hero-img.with-group {
  align-self: center;
  padding-left: 40px;
}
.hero-style-1 .hero-sticker {
  background-color: #f9b047;
  border-radius: 100%;
  width: 140px;
  height: 140px;
}
@media (max-width: 479px) {
  .hero-style-1 .hero-sticker {
    width: 85px;
    height: 85px;
  }
}
.hero-style-1 .hero-sticker.extbot-primary {
  background-color: #407ff0;
}
.hero-style-1 .hero-content {
  padding-left: 90px;
  width: 50%;
}
@media (max-width: 1399px) {
  .hero-style-1 .hero-content {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 991px) {
  .hero-style-1 .hero-content {
    padding-top: 70px;
    padding-bottom: 40px;
    width: 100%;
  }
}
.hero-style-1 .hero-content .category {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 25px;
  color: #f9b047;
  display: block;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .hero-style-1 .hero-content .category {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.hero-style-1 .hero-content .title {
  max-width: 625px;
  font-size: 95px;
  line-height: 105px;
  margin-bottom: 35px;
}
@media (max-width: 1399px) {
  .hero-style-1 .hero-content .title {
    font-size: 65px;
    line-height: 1.4;
  }
}
@media (max-width: 1199px) {
  .hero-style-1 .hero-content .title {
    font-size: 40px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .hero-style-1 .hero-content .title {
    font-size: 30px;
    line-height: 1.4;
    max-width: 350px;
    margin-bottom: 15px;
  }
}
.hero-style-1 .hero-content .desc {
  color: #57617b;
  font-size: 25px;
  line-height: 45px;
  max-width: 625px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .hero-style-1 .hero-content .desc {
    font-size: 18px;
    line-height: 30px;
    max-width: 380px;
  }
}
@media (max-width: 767px) {
  .hero-style-1 .hero-content .btn-custom-size {
    width: 150px;
    height: 50px;
    line-height: 47px;
    font-size: 14px;
  }
}
.hero-style-2.hero-bg {
  position: relative;
  overflow: hidden;
  height: 940px;
}
@media (max-width: 991px) {
  .hero-style-2.hero-bg {
    height: auto;
  }
}
.hero-style-2 .hero-container {
  height: 100%;
}
@media (min-width: 1400px) {
  .hero-style-2 .hero-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-2 .hero-container {
    max-width: 100%;
  }
}
.hero-style-2 .hero-item {
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
}
.hero-style-2 .hero-content {
  width: calc(100% - 500px);
  padding-right: 30px;
  align-self: center;
}
@media (max-width: 991px) {
  .hero-style-2 .hero-content {
    width: calc(100% - 230px);
  }
}
@media (max-width: 767px) {
  .hero-style-2 .hero-content {
    width: 100%;
    padding-bottom: 410px;
    text-align: center;
    padding-right: 0;
  }
}
.hero-style-2 .hero-content .title {
  font-size: 75px;
  line-height: 90px;
  margin-top: 90px;
  margin-bottom: 40px;
}
@media (max-width: 1399px) {
  .hero-style-2 .hero-content .title {
    font-size: 65px;
    line-height: 85px;
  }
}
@media (max-width: 1199px) {
  .hero-style-2 .hero-content .title {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .hero-style-2 .hero-content .title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 50px;
  }
}
.hero-style-2 .hero-content .desc {
  max-width: 750px;
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 40px;
}
@media (max-width: 1399px) {
  .hero-style-2 .hero-content .desc {
    max-width: 400px;
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .hero-style-2 .hero-content .desc {
    max-width: 100%;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    line-height: 30px;
  }
}
@media (max-width: 767px) {
  .hero-style-2 .hero-content .btn-custom-size {
    width: 190px;
    height: 60px;
    line-height: 57px;
    font-size: 14px;
  }
}
.hero-style-2 .hero-content .btn-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .hero-style-2 .hero-content .btn-wrap {
    margin-bottom: 90px;
  }
}
@media (max-width: 767px) {
  .hero-style-2 .hero-content .btn-wrap {
    justify-content: center;
  }
}
.hero-style-2 .hero-content .btn-wrap .popup-vimeo .icon {
  background-color: #ffffff;
  color: #fe6a21;
  border-radius: 50%;
  display: block;
  width: 70px;
  height: 70px;
  line-height: 67px;
  text-align: center;
  margin-left: 40px;
  position: relative;
  transition: all 0.3s ease 0s;
}
.hero-style-2 .hero-content .btn-wrap .popup-vimeo .icon:hover {
  background-color: #2f2b54;
  color: #ffffff;
}
.hero-style-2 .hero-content .btn-wrap .popup-vimeo .icon:hover span {
  border-left: solid 12px #ffffff;
}
@media (max-width: 767px) {
  .hero-style-2 .hero-content .btn-wrap .popup-vimeo .icon {
    width: 60px;
    height: 60px;
    line-height: 57px;
    margin-left: 20px;
  }
}
.hero-style-2 .hero-content .btn-wrap .popup-vimeo .icon span {
  width: 8px;
  height: 5px;
  border-left: solid 12px #fe6a21;
  border-bottom: solid 8px transparent;
  border-top: solid 7px transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.hero-style-2 .hero-content.white-text * {
  color: #ffffff;
}
.hero-style-2 .hero-img {
  position: absolute;
  padding: 15px;
  top: 160px;
  right: 0;
  z-index: 2;
  width: 500px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-2 .hero-img {
    top: 50%;
    width: 230px;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  .hero-style-2 .hero-img {
    top: calc(100% - 450px);
    width: calc(100% - 30px);
    left: 50%;
    transform: translateX(-50%);
  }
}
.hero-style-2 .hero-img:before {
  border: 2px solid rgb(255, 255, 255);
  border-radius: 44px;
  opacity: 0.349;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-style-2 .hero-sticker {
  background-color: rgb(255, 255, 255);
  transform: skewX(-34deg);
  position: absolute;
  width: 210px;
  height: 100%;
  top: -160px;
  right: 0;
  z-index: 1;
}
@media (max-width: 991px) {
  .hero-style-2 .hero-sticker {
    display: none;
  }
}
.hero-style-3.hero-bg {
  height: 980px;
  padding-bottom: 115px;
  background-position: bottom;
}
@media (max-width: 767px) {
  .hero-style-3.hero-bg {
    height: auto;
  }
}
.hero-style-3 .hero-container {
  height: 100%;
}
@media (min-width: 1400px) {
  .hero-style-3 .hero-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-3 .hero-container {
    max-width: 100%;
  }
}
.hero-style-3 .hero-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}
@media (max-width: 1399px) {
  .hero-style-3 .hero-item {
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .hero-style-3 .hero-item {
    flex-direction: column-reverse;
  }
}
.hero-style-3 .hero-content {
  align-self: center;
}
@media (min-width: 1200px) {
  .hero-style-3 .hero-content {
    width: 685px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-3 .hero-content {
    width: 350px;
  }
}
.hero-style-3 .hero-content .title {
  max-width: 650px;
  font-size: 75px;
  line-height: 84px;
  margin-bottom: 40px;
}
@media (max-width: 1399px) {
  .hero-style-3 .hero-content .title {
    font-size: 55px;
    line-height: 74px;
  }
}
@media (max-width: 1199px) {
  .hero-style-3 .hero-content .title {
    font-size: 35px;
    line-height: 54px;
    margin-bottom: 40px;
  }
}
.hero-style-3 .hero-content .desc {
  max-width: 615px;
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .hero-style-3 .hero-content .desc {
    font-size: 20px;
    line-height: 35px;
  }
}
.hero-style-3 .hero-content.white-text * {
  color: #ffffff;
}
@media (max-width: 991px) {
  .hero-style-3 .hero-content .btn-lg {
    width: 160px;
    height: 60px;
    line-height: 57px;
    font-size: 14px;
  }
}
.hero-style-3 .hero-content .btn-wrap .extbot-link {
  margin-left: 45px;
  font-size: 18px;
}
@media (max-width: 1199px) {
  .hero-style-3 .hero-content .btn-wrap .extbot-link {
    margin-left: 25px;
  }
}
@media (max-width: 479px) {
  .hero-style-3 .hero-content .btn-wrap .extbot-link {
    margin-left: 0;
    display: block;
    margin-top: 15px;
  }
}
.hero-style-3 .hero-img {
  display: flex;
  transform: rotate(-20deg);
}
@media (min-width: 1200px) {
  .hero-style-3 .hero-img {
    width: calc(100% - 685px);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hero-style-3 .hero-img {
    justify-content: flex-end;
    width: calc(100% - 350px);
  }
}
@media (max-width: 767px) {
  .hero-style-3 .hero-img {
    transform: none;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.hero-style-3 .hero-img [class*=single-img] {
  width: 38%;
  height: 425px;
}
@media (max-width: 767px) {
  .hero-style-3 .hero-img [class*=single-img] {
    height: auto;
  }
}
.hero-style-3 .hero-img [class*=single-img]:not(:last-child) {
  margin-right: 30px;
  margin-top: 90px;
}
.hero-style-3 .hero-img .single-img img {
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}
.hero-style-4 .container {
  height: 100%;
}
.hero-style-4.hero-bg {
  height: 1055px;
  background-position: bottom;
}
@media (max-width: 991px) {
  .hero-style-4.hero-bg {
    height: 850px;
  }
}
@media (max-width: 991px) {
  .hero-style-4.hero-bg {
    height: 750px;
  }
}
.hero-style-4 .hero-item {
  height: 100%;
}
.hero-style-4 .hero-content {
  padding-top: 165px;
  text-align: center;
}
@media (max-width: 991px) {
  .hero-style-4 .hero-content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.hero-style-4 .hero-content .title {
  color: #500836;
  font-size: 75px;
  line-height: 95px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .hero-style-4 .hero-content .title {
    font-size: 45px;
    line-height: 65px;
  }
}
@media (max-width: 767px) {
  .hero-style-4 .hero-content .title {
    font-size: 35px;
    line-height: 50px;
  }
}
.hero-style-4 .hero-content .title span {
  position: relative;
  z-index: 1;
}
.hero-style-4 .hero-content .title span:before {
  background-color: #ff8144;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  content: "";
  position: absolute;
  top: 0;
  left: -45px;
  z-index: -1;
}
.hero-style-4 .hero-content .desc {
  color: #534e4b;
  font-size: 25px;
  line-height: 45px;
}
.hero-style-4 .hero-btn-wrap a {
  display: inline-flex;
}
.hero-style-4 .hero-btn-wrap a:not(:last-child) {
  margin-right: 25px;
}
.hero-style-5.hero-bg {
  height: 1025px;
}
@media (max-width: 1199px) {
  .hero-style-5.hero-bg {
    height: 850px;
  }
}
@media (max-width: 991px) {
  .hero-style-5.hero-bg {
    height: 750px;
  }
}
@media (max-width: 767px) {
  .hero-style-5.hero-bg {
    height: auto;
  }
}
.hero-style-5 .hero-container {
  height: 100%;
}
@media (min-width: 1400px) {
  .hero-style-5 .hero-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .hero-style-5 .hero-container {
    max-width: 100%;
  }
}
.hero-style-5 .hero-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .hero-style-5 .hero-item {
    flex-direction: column;
    padding-bottom: 110px;
  }
}
.hero-style-5 .hero-content {
  align-self: center;
}
@media (max-width: 767px) {
  .hero-style-5 .hero-content {
    margin-bottom: 50px;
    margin-top: 35px;
    text-align: center;
  }
}
.hero-style-5 .hero-content .title {
  max-width: 715px;
  font-size: 75px;
  line-height: 84px;
  margin-bottom: 45px;
}
@media (max-width: 1399px) {
  .hero-style-5 .hero-content .title {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (max-width: 991px) {
  .hero-style-5 .hero-content .title {
    font-size: 30px;
    line-height: 50px;
  }
}
.hero-style-5 .hero-content .desc {
  max-width: 715px;
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 40px;
}
@media (max-width: 1399px) {
  .hero-style-5 .hero-content .desc {
    font-size: 16px;
    line-height: 35px;
  }
}
.hero-style-5 .hero-content.white-text * {
  color: #ffffff;
}
.hero-style-5 .hero-content .btn-wrap .extbot-link {
  margin-left: 45px;
  font-size: 18px;
}
.hero-style-5 .hero-img {
  padding-left: 30px;
  position: relative;
  left: -35px;
}
.hero-style-5 .hero-img .single-img {
  position: relative;
  right: -40px;
}
.hero-style-5 .hero-sticker {
  background-color: #f0582e;
  border-radius: 50%;
  width: 195px;
  height: 195px;
}
.hero-style-5 .hero-sticker-wrap {
  position: absolute;
  bottom: -60px;
  left: 0;
  z-index: -1;
}
@media (max-width: 1199px) {
  .hero-style-5 .hero-sticker-wrap {
    left: 30px;
  }
}

/*----------------------------------------*/
/* 04. Navigation
/*----------------------------------------*/
.partner-arrow-wrap,
.testimonial-arrow-wrap,
.screen-arrow-wrap,
.blog-arrow-wrap {
  display: flex;
}
.partner-arrow-wrap.style-2 .testimonial-button-prev i, .partner-arrow-wrap.style-2 .testimonial-button-next i,
.testimonial-arrow-wrap.style-2 .testimonial-button-prev i,
.testimonial-arrow-wrap.style-2 .testimonial-button-next i,
.screen-arrow-wrap.style-2 .testimonial-button-prev i,
.screen-arrow-wrap.style-2 .testimonial-button-next i,
.blog-arrow-wrap.style-2 .testimonial-button-prev i,
.blog-arrow-wrap.style-2 .testimonial-button-next i {
  color: #ffffff;
}
.partner-arrow-wrap.style-2 .testimonial-button-prev i:hover, .partner-arrow-wrap.style-2 .testimonial-button-next i:hover,
.testimonial-arrow-wrap.style-2 .testimonial-button-prev i:hover,
.testimonial-arrow-wrap.style-2 .testimonial-button-next i:hover,
.screen-arrow-wrap.style-2 .testimonial-button-prev i:hover,
.screen-arrow-wrap.style-2 .testimonial-button-next i:hover,
.blog-arrow-wrap.style-2 .testimonial-button-prev i:hover,
.blog-arrow-wrap.style-2 .testimonial-button-next i:hover {
  border-color: #1f1927;
  background-color: #1f1927;
}
.partner-arrow-wrap.style-3 .testimonial-button-prev, .partner-arrow-wrap.style-3 .testimonial-button-next,
.testimonial-arrow-wrap.style-3 .testimonial-button-prev,
.testimonial-arrow-wrap.style-3 .testimonial-button-next,
.screen-arrow-wrap.style-3 .testimonial-button-prev,
.screen-arrow-wrap.style-3 .testimonial-button-next,
.blog-arrow-wrap.style-3 .testimonial-button-prev,
.blog-arrow-wrap.style-3 .testimonial-button-next {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}
.partner-arrow-wrap.style-3 .testimonial-button-prev i, .partner-arrow-wrap.style-3 .testimonial-button-next i,
.testimonial-arrow-wrap.style-3 .testimonial-button-prev i,
.testimonial-arrow-wrap.style-3 .testimonial-button-next i,
.screen-arrow-wrap.style-3 .testimonial-button-prev i,
.screen-arrow-wrap.style-3 .testimonial-button-next i,
.blog-arrow-wrap.style-3 .testimonial-button-prev i,
.blog-arrow-wrap.style-3 .testimonial-button-next i {
  border-color: #edc8c1;
  color: #edc8c1;
}
.partner-arrow-wrap.style-3 .testimonial-button-prev i:hover, .partner-arrow-wrap.style-3 .testimonial-button-next i:hover,
.testimonial-arrow-wrap.style-3 .testimonial-button-prev i:hover,
.testimonial-arrow-wrap.style-3 .testimonial-button-next i:hover,
.screen-arrow-wrap.style-3 .testimonial-button-prev i:hover,
.screen-arrow-wrap.style-3 .testimonial-button-next i:hover,
.blog-arrow-wrap.style-3 .testimonial-button-prev i:hover,
.blog-arrow-wrap.style-3 .testimonial-button-next i:hover {
  background-color: #ff8144;
  border-color: #ff8144;
  color: #ffffff;
}
.partner-arrow-wrap.style-3 .testimonial-button-next,
.testimonial-arrow-wrap.style-3 .testimonial-button-next,
.screen-arrow-wrap.style-3 .testimonial-button-next,
.blog-arrow-wrap.style-3 .testimonial-button-next {
  left: auto;
  right: 0;
}
.partner-arrow-wrap.style-4 .blog-button-prev i, .partner-arrow-wrap.style-4 .blog-button-next i,
.testimonial-arrow-wrap.style-4 .blog-button-prev i,
.testimonial-arrow-wrap.style-4 .blog-button-next i,
.screen-arrow-wrap.style-4 .blog-button-prev i,
.screen-arrow-wrap.style-4 .blog-button-next i,
.blog-arrow-wrap.style-4 .blog-button-prev i,
.blog-arrow-wrap.style-4 .blog-button-next i {
  border-color: #eeeeee;
  color: #0f034a;
  font-size: 25px;
  width: 52px;
  height: 52px;
  line-height: 51px;
  border-radius: 10px;
}
.partner-arrow-wrap.style-4 .blog-button-prev i:hover, .partner-arrow-wrap.style-4 .blog-button-next i:hover,
.testimonial-arrow-wrap.style-4 .blog-button-prev i:hover,
.testimonial-arrow-wrap.style-4 .blog-button-next i:hover,
.screen-arrow-wrap.style-4 .blog-button-prev i:hover,
.screen-arrow-wrap.style-4 .blog-button-next i:hover,
.blog-arrow-wrap.style-4 .blog-button-prev i:hover,
.blog-arrow-wrap.style-4 .blog-button-next i:hover {
  background-color: #5138ee;
  border-color: #5138ee;
  color: #ffffff;
}
.partner-button-prev i, .partner-button-next i,
.testimonial-button-prev i,
.testimonial-button-next i,
.screen-button-prev i,
.screen-button-next i,
.blog-button-prev i,
.blog-button-next i {
  border: 2px solid rgb(227, 232, 241);
  transition: all 0.3s ease 0s;
  color: #b6bcc4;
  font-size: 20px;
  border-radius: 50%;
  width: 76px;
  height: 76px;
  line-height: 75px;
  display: block;
  text-align: center;
}
.partner-button-prev i:hover, .partner-button-next i:hover,
.testimonial-button-prev i:hover,
.testimonial-button-next i:hover,
.screen-button-prev i:hover,
.screen-button-next i:hover,
.blog-button-prev i:hover,
.blog-button-next i:hover {
  background-color: #407ff0;
  border-color: #407ff0;
  color: #ffffff;
}
.partner-button-prev,
.testimonial-button-prev,
.screen-button-prev,
.blog-button-prev {
  margin-right: 20px;
}

/* ---Pagination--- */
.partner-pagination {
  position: relative;
  margin-top: 80px;
}
.partner-pagination .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}
.partner-pagination.style-2 .swiper-pagination-bullet {
  background-color: #25b760;
  width: 15px;
  height: 15px;
}
.partner-pagination.style-3 .swiper-pagination-bullet {
  background-color: #ff8144;
  width: 15px;
  height: 15px;
}

/*----------------------------------------*/
/*  05. Features
/*----------------------------------------*/
.feature-area {
  padding-top: 115px;
}
.feature-area.with-background {
  background-color: #f5f8fb;
}
@media (min-width: 1400px) {
  .feature-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .feature-container {
    max-width: 100%;
  }
}
.feature-item {
  background-color: #ffffff;
  border-radius: 30px;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 45px;
  padding-left: 40px;
  padding-right: 40px;
}
.feature-icon {
  margin-bottom: 35px;
}
.feature-content .title {
  margin-bottom: 25px;
  font-size: 30px;
}
.feature-content .desc {
  color: #57617b;
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 0;
}
.feature-pagination {
  position: relative;
  padding-top: 120px;
}
.feature-pagination:before {
  background-color: #ffffff;
  height: 3px;
  width: 100%;
  content: "";
  position: absolute;
  bottom: 11px;
  left: 0;
  z-index: -1;
}
.feature-pagination .swiper-pagination-bullet {
  background-color: #ced6e5;
  opacity: 1;
  width: 12px;
  height: 12px;
  margin: 0 10px !important;
}
.feature-pagination .swiper-pagination-bullet-active {
  background-color: #407ff0;
}
.feature-style-1 .feature-item {
  position: relative;
}
.feature-style-1 .feature-item:before {
  content: url("../images/feature/dot/1.png");
  position: absolute;
  top: 30px;
  left: 35px;
}
.feature-style-1 .feature-content .title {
  transition: all 0.3s ease 0s;
}
.feature-style-1 .feature-content .title:hover {
  color: #407ff0;
}
.feature-style-2 {
  background-color: #fefdfd;
  margin-bottom: -305px;
}
.feature-style-2 .section-title {
  color: #2f2b54;
}
.feature-style-2 .feature-item {
  background-color: #fbf7f5;
  display: flex;
}
@media (max-width: 767px) {
  .feature-style-2 .feature-item {
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }
}
.feature-style-2 .feature-icon {
  width: 110px;
}
.feature-style-2 .feature-content {
  width: calc(100% - 110px);
  text-align: left;
  padding-left: 50px;
}
@media (max-width: 1199px) {
  .feature-style-2 .feature-content {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .feature-style-2 .feature-content {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
}
.feature-style-2 .feature-content .title {
  font-size: 29px;
}
@media (max-width: 1199px) {
  .feature-style-2 .feature-content .title {
    font-size: 25px;
  }
}
.feature-style-2 .feature-content .desc {
  max-width: 305px;
}
@media (max-width: 767px) {
  .feature-style-2 .feature-content .desc {
    margin-left: auto;
    margin-right: auto;
  }
}
.feature-style-3 {
  padding-bottom: 330px;
  padding-top: 125px;
}
.feature-style-3 .section-title {
  color: #1f1927;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .feature-style-3 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.feature-style-3 .section-desc {
  color: #6f7872;
}
.feature-style-3 .feature-item {
  background-color: #f4fbf7;
  display: flex;
  padding-top: 60px;
  padding-left: 55px;
  padding-right: 55px;
  padding-bottom: 50px;
}
@media (max-width: 1199px) {
  .feature-style-3 .feature-item {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 575px) {
  .feature-style-3 .feature-item {
    flex-direction: column;
    align-items: center;
  }
}
.feature-style-3 .feature-item:hover .feature-icon {
  background-color: #25b760;
}
.feature-style-3 .feature-item:hover .feature-icon img {
  filter: brightness(0) invert(1);
}
.feature-style-3 .feature-icon {
  background-color: #d5f3e1;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  line-height: 110px;
  margin-bottom: 0;
  transition: all 0.3s ease 0s;
}
.feature-style-3 .feature-content {
  width: calc(100% - 110px);
  text-align: left;
  padding-left: 50px;
}
@media (max-width: 575px) {
  .feature-style-3 .feature-content {
    padding-left: 0;
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }
}
.feature-style-3 .feature-content .title {
  color: #1f1927;
  font-size: 29px;
}
.feature-style-3 .feature-content .desc {
  max-width: 305px;
  margin-bottom: 0;
  color: #5f6762;
}
.feature-style-3 .feature-btn-wrap {
  padding-top: 80px;
  display: flex;
  justify-content: center;
}
.feature-style-4 {
  padding-top: 125px;
}
.feature-style-4 .feature-img .img-wrap {
  position: relative;
  display: flex;
  justify-content: center;
}
.feature-style-4 .feature-img .img-wrap .secondary-img {
  position: absolute;
  bottom: 0;
  transform: rotateX(45deg);
}
.feature-style-4 .feature-list-content {
  padding-left: 95px;
}
@media (max-width: 1199px) {
  .feature-style-4 .feature-list-content {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .feature-style-4 .feature-list-content {
    padding-left: 0;
  }
}
.feature-style-4 .feature-title {
  color: #500836;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 1199px) {
  .feature-style-4 .feature-title {
    font-size: 45px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .feature-style-4 .feature-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.feature-style-4 .feature-desc {
  color: #534e4b;
  font-size: 25px;
  line-height: 42px;
  margin-bottom: 95px;
}
@media (max-width: 767px) {
  .feature-style-4 .feature-desc {
    font-size: 20px;
  }
}
.feature-style-4 .feature-item {
  background-color: #fff4ef;
  display: flex;
  padding-top: 45px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 40px;
}
@media (max-width: 1199px) {
  .feature-style-4 .feature-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .feature-style-4 .feature-item {
    flex-direction: column;
  }
}
.feature-style-4 .feature-item:hover .feature-icon {
  background-color: #ff8144;
}
.feature-style-4 .feature-item:hover .feature-icon img {
  filter: brightness(0) invert(1);
}
.feature-style-4 .feature-icon {
  background-color: #ffdfcf;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  line-height: 110px;
  margin-bottom: 0;
  transition: all 0.3s ease 0s;
}
@media (max-width: 575px) {
  .feature-style-4 .feature-icon {
    margin-left: auto;
    margin-right: auto;
  }
}
.feature-style-4 .feature-content {
  width: calc(100% - 110px);
  text-align: left;
  padding-left: 50px;
}
@media (max-width: 1199px) {
  .feature-style-4 .feature-content {
    padding-left: 30px;
  }
}
@media (max-width: 575px) {
  .feature-style-4 .feature-content {
    text-align: center;
    padding-top: 25px;
    padding-left: 0;
    width: 100%;
  }
}
.feature-style-4 .feature-content .title {
  margin-bottom: 20px;
  color: #1f1927;
  font-size: 29px;
}
@media (max-width: 1199px) {
  .feature-style-4 .feature-content .title {
    font-size: 24px;
  }
}
.feature-style-4 .feature-content .desc {
  max-width: 305px;
  margin-bottom: 0;
  color: #5f6762;
}
@media (max-width: 575px) {
  .feature-style-4 .feature-content .desc {
    max-width: 100%;
  }
}
.feature-style-4 .feature-wrap [class*=feature-item]:not(:last-child) {
  margin-bottom: 30px;
}
.feature-style-5 .section-title {
  color: #07135b;
  font-size: 55px;
  line-height: 70px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .feature-style-5 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.feature-style-5 .section-desc {
  font-size: 25px;
  line-height: 42px;
}
@media (max-width: 991px) {
  .feature-style-5 .section-desc {
    font-size: 20px;
    line-height: 40px;
  }
}
.feature-style-5 .feature-item {
  transition: all 0.3s ease 0s;
  border-radius: 30px;
  text-align: center;
  padding-left: 35px;
  padding-right: 35px;
}
@media (max-width: 991px) {
  .feature-style-5 .feature-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.feature-style-5 .feature-item:hover {
  background-color: #f5f6ff;
}
.feature-style-5 .feature-item:hover .feature-icon {
  background-color: #1a2c99;
}
.feature-style-5 .feature-item:hover .feature-icon img {
  filter: brightness(0) invert(1);
}
.feature-style-5 .feature-item:hover .feature-content .title {
  color: #1a2c99;
}
.feature-style-5 .feature-item.swiper-slide-active {
  background-color: #f5f6ff;
}
.feature-style-5 .feature-item.swiper-slide-active .feature-icon {
  background-color: #1a2c99;
}
.feature-style-5 .feature-item.swiper-slide-active .feature-icon img {
  filter: brightness(0) invert(1);
}
.feature-style-5 .feature-item.swiper-slide-active .feature-content .title {
  color: #1a2c99;
}
.feature-style-5 .feature-icon {
  background-color: #f4f6ff;
  border-radius: 50%;
  width: 110px;
  height: 110px;
  line-height: 110px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
.feature-style-5 .feature-content .title {
  margin-bottom: 20px;
  color: #07135b;
}
.feature-style-5 .feature-content .desc {
  color: #4a4b51;
}

/*----------------------------------------*/
/*  06. Partner
/*----------------------------------------*/
.partner-area.with-background {
  background-color: #f5f8fb;
  padding-top: 105px;
  padding-bottom: 140px;
}
.partner-area .section-style-2 {
  padding-bottom: 65px;
}
@media (min-width: 1400px) {
  .partner-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .partner-container {
    max-width: 100%;
  }
}
.partner-img {
  border: 2px solid rgb(227, 232, 241);
  transition: all 0.3s ease 0s;
  border-radius: 25px;
  padding: 60px;
  display: block;
}
@media (max-width: 991px) {
  .partner-img {
    padding: 30px;
  }
}
.partner-img:hover {
  border-color: #407ff0;
}
.partner-style-2 {
  padding-top: 155px;
  padding-bottom: 155px;
}
.partner-style-2 .partner-img {
  padding: 50px 60px;
}
@media (max-width: 1199px) {
  .partner-style-2 .partner-img {
    padding: 30px;
  }
}
.partner-style-2 .partner-img:hover {
  border-color: #fe6a21;
}
.partner-style-3 {
  padding-top: 0;
  padding-bottom: 120px;
}
.partner-style-3 .section-title {
  color: #1f1927;
  font-size: 55px;
  line-height: 70px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .partner-style-3 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.partner-style-3 .section-desc {
  color: #6f7872;
}
.partner-style-3 .partner-img {
  padding: 45px 60px;
}
@media (max-width: 1199px) {
  .partner-style-3 .partner-img {
    padding: 30px;
  }
}
.partner-style-3 .partner-img:hover {
  border-color: #25b760;
}
.partner-style-3 .partner-pagination {
  margin-top: 90px;
}
.partner-style-3 .partner-pagination .swiper-pagination-bullet {
  margin: 0 10px;
}
.partner-style-4 {
  padding-top: 120px;
}
.partner-style-4 .section-title {
  color: #500836;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .partner-style-4 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.partner-style-4 .section-desc {
  color: #534e4b;
}
.partner-style-4 .partner-img {
  padding: 50px 65px;
}
@media (max-width: 1199px) {
  .partner-style-4 .partner-img {
    padding: 30px;
  }
}
.partner-style-4 .partner-img:hover {
  border-color: #ff8144;
}
.partner-style-5 {
  padding-top: 140px;
}
.partner-style-5 .partner-slider-4 .swiper-wrapper {
  align-items: center;
}
.partner-style-5 .partner-img {
  transition: all 0.3s ease 0s;
  border: 0;
  padding: 0;
  position: relative;
}
.partner-style-5 .partner-img:hover .primary-img {
  opacity: 0;
}
.partner-style-5 .partner-img:hover .secondary-img {
  opacity: 1;
}
.partner-style-5 .partner-img .secondary-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

/*----------------------------------------*/
/*  07. Banner
/*----------------------------------------*/
.banner-area {
  padding-top: 140px;
}
@media (min-width: 1400px) {
  .banner-container {
    max-width: 1370px;
  }
}
@media (min-width: 1400px) {
  .banner-container-2 {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner-container-2 {
    max-width: 100%;
  }
}
.banner-category {
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #f9b047;
  font-size: 25px;
  display: block;
  margin-bottom: 25px;
}
.banner-title {
  max-width: 520px;
  font-size: 65px;
  line-height: 80px;
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .banner-title {
    font-size: 35px;
    line-height: 1.4;
  }
}
@media (max-width: 767px) {
  .banner-title {
    font-size: 32px;
  }
}
.banner-desc {
  max-width: 545px;
  color: #57617b;
  font-size: 25px;
  line-height: 45px;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .banner-desc {
    font-size: 20px;
  }
}
.banner-btn-wrap .title {
  color: #407ff0;
  margin-bottom: 35px;
}
.banner-btn-wrap a {
  display: inline-flex;
  justify-content: center;
}
.banner-btn-wrap a:not(:last-child) {
  margin-right: 25px;
}
@media (max-width: 991px) {
  .banner-common .banner-content {
    padding-top: 30px;
  }
}
.banner-content.common-style .banner-content {
  align-self: center;
}
.banner-content.common-style .banner-category {
  color: #ff8144;
  font-size: 23px;
  line-height: 28px;
}
.banner-content.common-style .banner-title {
  font-family: "Gordita";
  color: #500836;
  font-size: 55px;
  line-height: 70px;
  max-width: 100%;
}
@media (max-width: 767px) {
  .banner-content.common-style .banner-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.banner-content.common-style .banner-desc {
  color: #534e4b;
  line-height: 42px;
}
.banner-style-2 .banner-img {
  display: flex;
  align-items: flex-end;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .banner-style-2 .banner-img .primary-img {
    max-width: 60%;
  }
}
@media (max-width: 767px) {
  .banner-style-2 .banner-img .primary-img {
    max-width: 60%;
  }
}
.banner-style-2 .banner-inner-img {
  position: relative;
  top: -70px;
  left: -90px;
}
.banner-style-2 .banner-inner-img .popup-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.banner-style-2 .banner-title {
  max-width: 680px;
}
@media (min-width: 1400px) {
  .banner-style-3 .banner-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .banner-style-3 .banner-container {
    max-width: 100%;
  }
}
.banner-style-3 .banner-item {
  padding-top: 85px;
  padding-bottom: 100px;
  border-radius: 25px;
}
.banner-style-3 .banner-content {
  max-width: 750px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .banner-style-3 .banner-content {
    max-width: 100%;
    width: calc(100% - 30px);
  }
}
.banner-style-3 .banner-content * {
  color: #ffffff;
}
.banner-style-3 .banner-title {
  max-width: 100%;
  font-size: 55px;
  line-height: 65px;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  .banner-style-3 .banner-title {
    font-size: 35px;
    line-height: 1.4;
  }
}
.banner-style-3 .banner-title span {
  color: #ffba00;
}
.banner-style-3 .banner-desc {
  max-width: 100%;
}
@media (max-width: 767px) {
  .banner-style-3 .banner-desc {
    font-size: 20px;
    line-height: 1.5;
  }
}
@media (min-width: 1400px) {
  .banner-style-4 .banner-container {
    max-width: 1360px;
  }
}
.banner-style-4 .banner-content {
  padding-left: 90px;
  align-self: center;
}
@media (max-width: 991px) {
  .banner-style-4 .banner-content {
    padding-left: 0;
    padding-top: 50px;
  }
}
.banner-style-4 .banner-content .btn-wrap {
  margin-top: 65px;
}
.banner-style-4 .banner-category {
  font-family: "Roboto", sans-serif;
  color: #fe6a21;
  font-size: 23px;
  line-height: 28px;
  font-weight: 500;
}
.banner-style-4 .banner-title {
  color: #2f2b54;
  font-size: 55px;
  line-height: 70px;
}
.banner-style-4 .banner-title span {
  color: #fe6a21;
}
@media (max-width: 767px) {
  .banner-style-4 .banner-title {
    font-size: 35px;
    line-height: 50px;
    max-width: 430px;
  }
}
.banner-style-4 .banner-desc {
  max-width: 100%;
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .banner-style-4 .banner-desc {
    font-size: 20px;
    line-height: 30px;
  }
}
.banner-style-5 .banner-bg {
  border-radius: 25px;
  height: 420px;
  padding-left: 100px;
  padding-right: 100px;
}
@media (max-width: 1199px) {
  .banner-style-5 .banner-bg {
    padding-left: 30px;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .banner-style-5 .banner-bg {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    height: auto;
  }
}
.banner-style-5 .banner-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .banner-style-5 .banner-item {
    flex-direction: column;
    text-align: center;
  }
}
.banner-style-5 .banner-category {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fe6a21;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 20px;
}
.banner-style-5 .banner-title {
  color: #2f2b54;
  font-size: 55px;
  line-height: 70px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .banner-style-5 .banner-title {
    font-size: 35px;
    line-height: 50px;
    max-width: 100%;
  }
}
@media (max-width: 479px) {
  .banner-style-5 .banner-title {
    font-size: 30px;
  }
}
.banner-style-5 .banner-desc {
  line-height: 42px;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .banner-style-5 .banner-desc {
    font-size: 18px;
  }
}
.banner-style-5 .banner-btn {
  display: block;
}
.banner-style-5 .banner-btn[class*=banner-btn]:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .banner-style-5 .banner-btn[class*=banner-btn]:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: 15px;
  }
}
.banner-style-6 .banner-item {
  margin-top: -330px;
}
.banner-style-6 .banner-bg {
  height: 600px;
}
@media (max-width: 1199px) {
  .banner-style-6 .banner-bg {
    background-position: center;
  }
}
.banner-style-6 .banner-img .popup-btn {
  height: 100%;
  display: flex;
}
.banner-style-6 .banner-content {
  text-align: center;
  margin-top: 135px;
  margin-bottom: 225px;
}
.banner-style-6 .banner-category {
  color: #25b760;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 23px;
  line-height: 28px;
}
.banner-style-6 .banner-title {
  color: #1f1927;
  max-width: 100%;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .banner-style-6 .banner-title {
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 20px;
  }
}
.banner-style-6 .banner-desc {
  color: #6f7872;
  margin-left: auto;
  margin-right: auto;
  line-height: 42px;
  max-width: 710px;
}
.banner-style-6 .banner-btn-wrap a:not(:last-child) {
  margin-right: 25px;
}
@media (max-width: 479px) {
  .banner-style-6 .banner-btn-wrap a:not(:last-child) {
    margin-right: 0;
  }
}
.banner-style-7 {
  padding-top: 0;
  padding-bottom: 110px;
  margin-top: -200px;
}
.banner-style-7 .banner-img {
  padding-right: 30px;
}
.banner-style-7 .banner-category {
  color: #25b760;
  font-size: 23px;
  line-height: 28px;
}
.banner-style-7 .banner-title {
  max-width: 615px;
  font-size: 55px;
  line-height: 70px;
  color: #1f1927;
}
@media (max-width: 1199px) {
  .banner-style-7 .banner-title {
    font-size: 40px;
    line-height: 60px;
  }
}
@media (max-width: 767px) {
  .banner-style-7 .banner-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.banner-style-7 .banner-desc {
  color: #6f7872;
  line-height: 42px;
}
.banner-style-8 {
  padding-top: 0;
  height: 635px;
  background-position: center;
}
@media (max-width: 991px) {
  .banner-style-8 {
    background-position: left;
  }
}
.banner-style-8 .container {
  height: 100%;
}
.banner-style-8 .container .row {
  height: 100%;
}
.banner-style-9 .banner-img {
  border-radius: 25px;
  height: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-style-9 .banner-content {
  padding-left: 90px;
}
@media (max-width: 1199px) {
  .banner-style-9 .banner-content {
    padding-left: 30px;
  }
}
@media (max-width: 991px) {
  .banner-style-9 .banner-content {
    padding-top: 30px;
    padding-left: 0;
  }
}
.banner-style-9 .banner-category {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}
.banner-style-10 .banner-content {
  padding-left: 75px;
}
@media (max-width: 1399px) {
  .banner-style-10 .banner-content {
    padding-left: 25px;
  }
}
@media (max-width: 991px) {
  .banner-style-10 .banner-content {
    padding-top: 25px;
    padding-left: 0;
  }
}
.banner-style-10 .banner-category {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #f0582e;
  font-size: 23px;
  line-height: 28px;
}
.banner-style-10 .banner-title {
  color: #07135b;
  font-size: 55px;
  line-height: 70px;
  max-width: 100%;
}
@media (max-width: 1199px) {
  .banner-style-10 .banner-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.banner-style-10 .banner-desc {
  color: #4a4b51;
  max-width: 100%;
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .banner-style-10 .banner-desc {
    font-size: 20px;
  }
}
.banner-style-10 .banner-btn-wrap {
  margin-top: 45px;
}
.banner-style-11 {
  background-color: #f4f5ff;
  padding-top: 135px;
  padding-bottom: 140px;
}
.banner-style-11 .banner-category {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #f0582e;
  font-size: 23px;
  line-height: 28px;
}
.banner-style-11 .banner-title {
  color: #07135b;
  font-size: 55px;
  line-height: 70px;
  max-width: 100%;
  margin-bottom: 25px;
}
@media (max-width: 1199px) {
  .banner-style-11 .banner-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.banner-style-11 .banner-desc {
  color: #4a4b51;
  max-width: 580px;
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 40px;
}
@media (max-width: 1199px) {
  .banner-style-11 .banner-desc {
    font-size: 20px;
    line-height: 40px;
  }
}
.banner-style-11 .banner-img {
  position: relative;
  padding-left: 45px;
  z-index: 1;
  left: -45px;
}
@media (max-width: 991px) {
  .banner-style-11 .banner-img {
    margin-top: 50px;
  }
}
.banner-style-11 .banner-sticker {
  border-radius: 50%;
  width: 179px;
  height: 179px;
}
.banner-style-11 .banner-sticker-wrap.style-1 {
  position: absolute;
  top: -55px;
  right: -45px;
  z-index: -1;
}
@media (max-width: 767px) {
  .banner-style-11 .banner-sticker-wrap.style-1 {
    display: none;
  }
}
.banner-style-11 .banner-sticker-wrap.style-1 .banner-sticker {
  background-color: #f0582e;
}
.banner-style-11 .banner-sticker-wrap.style-2 {
  position: absolute;
  bottom: -40px;
  left: -25px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .banner-style-11 .banner-sticker-wrap.style-2 {
    left: 0;
  }
}
@media (max-width: 767px) {
  .banner-style-11 .banner-sticker-wrap.style-2 {
    display: none;
  }
}
.banner-style-11 .banner-sticker-wrap.style-2 .banner-sticker {
  background-color: #1a2c99;
}

/*----------------------------------------*/
/*  08. Pricing
/*----------------------------------------*/
.pricing-area {
  padding-top: 115px;
  padding-bottom: 140px;
}
.pricing-area .section-title {
  margin-bottom: 20px;
}
.pricing-tab {
  margin-bottom: 80px;
  text-align: center;
}
.pricing-tab-nav {
  background-color: #f4fbf7;
  display: inline-flex;
  border-radius: 24px;
  justify-content: center;
}
.pricing-tab-nav li a {
  border-radius: 24px;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  display: block;
  font-size: 18px;
  color: #1f1927;
  font-weight: 500;
}
.pricing-item {
  border: 2px solid #eaeff4;
  transition: all 0.3s ease 0s;
  border-radius: 30px;
  text-align: center;
  padding-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
}
.pricing-item:hover, .pricing-item.active {
  border-color: #407ff0;
}
.pricing-item:hover .pricing-info, .pricing-item.active .pricing-info {
  background-color: #407ff0;
}
.pricing-item:hover .pricing-info span, .pricing-item.active .pricing-info span {
  color: #ffffff;
}
.pricing-item:hover .pricing-btn-wrap .btn-custom-size, .pricing-item.active .pricing-btn-wrap .btn-custom-size {
  background-color: #407ff0;
  border-color: #407ff0;
  color: #ffffff;
}
.pricing-info {
  background-color: #f5f8fb;
  text-align: center;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
}
.pricing-info span {
  display: block;
  line-height: 1;
}
.pricing-info span.price {
  margin-bottom: 10px;
  color: #042659;
  font-weight: 700;
  font-size: 50px;
}
.pricing-info span.month {
  margin-left: 15px;
}
.pricing-title {
  margin-bottom: 30px;
  font-size: 33px;
}
@media (max-width: 1199px) {
  .pricing-title {
    font-size: 30px;
  }
}
.pricing-desc {
  border-bottom: 1px solid #eaeff4;
  color: #57617b;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 35px;
  padding-bottom: 35px;
}
.pricing-list li a {
  color: #38475e;
  font-weight: 500;
  font-size: 20px;
  line-height: 48px;
}
.pricing-list li a.line-through {
  text-decoration: line-through;
  color: #adb8c7;
}
.pricing-btn-wrap {
  margin-top: 35px;
}
.pricing-style-2 .section-title {
  color: #2f2b54;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .pricing-style-2 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.pricing-style-2 .section-desc {
  line-height: 42px;
}
.pricing-style-2 .pricing-tab-nav {
  background-color: #f8f3f0;
}
.pricing-style-2 .pricing-tab-nav li a {
  font-family: "Gordita";
  padding-top: 10px;
  padding-bottom: 10px;
}
.pricing-style-2 .pricing-tab-nav li a.active {
  background-color: #fe6a21;
  color: #ffffff;
}
.pricing-style-2 .pricing-item {
  border: 1px solid #f4eeea;
  text-align: left;
  padding-top: 70px;
}
@media (max-width: 991px) {
  .pricing-style-2 .pricing-item {
    margin-bottom: 50px;
  }
}
.pricing-style-2 .pricing-item:hover {
  background-color: #2f2b54;
  border-color: #2f2b54;
}
.pricing-style-2 .pricing-item:hover .pricing-wrap:before {
  background-color: rgb(234, 239, 244);
  opacity: 0.071;
}
.pricing-style-2 .pricing-item:hover .pricing-title {
  color: #ffffff;
}
.pricing-style-2 .pricing-item:hover .pricing-desc {
  color: #ffffff;
}
.pricing-style-2 .pricing-item:hover .pricing-info {
  background-color: transparent;
}
.pricing-style-2 .pricing-item:hover .pricing-info:before {
  border-radius: 25px;
  opacity: 0.05;
  visibility: visible;
}
.pricing-style-2 .pricing-item:hover .pricing-info span.price, .pricing-style-2 .pricing-item:hover .pricing-info span.month {
  color: #ffffff;
}
.pricing-style-2 .pricing-item:hover .pricing-list-wrap li a {
  color: #ffffff;
}
.pricing-style-2 .pricing-item:hover .pricing-list-wrap .btn-outline {
  background-color: #fe6a21;
  border-color: #fe6a21;
}
@media (min-width: 1200px) {
  .pricing-style-2 .pricing-item-wrap {
    --bs-gutter-x: 70px;
  }
}
.pricing-style-2 .pricing-wrap {
  position: relative;
  margin-bottom: 45px;
  padding-bottom: 55px;
  display: flex;
  align-items: center;
}
@media (max-width: 479px) {
  .pricing-style-2 .pricing-wrap {
    flex-direction: column;
  }
}
.pricing-style-2 .pricing-wrap:before {
  background-color: #f4eeea;
  height: 2px;
  width: 100%;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease 0s;
}
.pricing-style-2 .pricing-wrap .title-with-desc {
  width: calc(100% - 150px);
  padding-left: 50px;
}
@media (max-width: 479px) {
  .pricing-style-2 .pricing-wrap .title-with-desc {
    padding-top: 25px;
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
}
.pricing-style-2 .pricing-info {
  background-color: #f8f3f0;
  border-radius: 25px;
  margin-bottom: 0;
  width: 150px;
  align-self: center;
  position: relative;
}
.pricing-style-2 .pricing-info:before {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.pricing-style-2 .pricing-title {
  margin-bottom: 20px;
}
.pricing-style-2 .pricing-desc {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.pricing-style-2 .pricing-list.style-2 li a {
  line-height: 58px;
}
.pricing-style-2 .pricing-list.style-2 li a {
  color: #57617b;
  font-size: 19px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .pricing-style-2 .pricing-list.style-2 li a {
    line-height: 40px;
    font-size: 18px;
    display: flex;
    align-items: baseline;
  }
}
.pricing-style-2 .pricing-list.style-2 li a i {
  color: #fe6a21;
  margin-right: 10px;
  font-size: 23px;
}
.pricing-style-2 .pricing-list.style-2 .pricing-list-wrap {
  padding-left: 30px;
  padding-right: 30px;
}
.pricing-style-2 .pricing-list-wrap {
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 479px) {
  .pricing-style-2 .pricing-list-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}
.pricing-style-2 .pricing-list-wrap .pricing-btn-wrap {
  margin-top: 40px;
}
.pricing-style-2 .pricing-list-wrap .btn-outline {
  border: 2px solid #f8f3f0;
  background-color: #f8f3f0;
}
.pricing-style-2 .pricing-list-wrap .btn i {
  margin-left: 20px;
}
.pricing-style-3 .section-title {
  color: #1f1927;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .pricing-style-3 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.pricing-style-3 .section-desc {
  color: #6f7872;
  line-height: 42px;
  margin-bottom: 70px;
}
.pricing-style-3 .pricing-tab-nav li a {
  font-family: "Gordita";
}
.pricing-style-3 .pricing-tab-nav li a.active {
  background-color: #25b760;
  color: #ffffff;
}
.pricing-style-3 .pricing-item {
  transition: all 0.3s ease 0s;
  transition-delay: 0.02s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
@media (max-width: 991px) {
  .pricing-style-3 .pricing-item {
    margin-bottom: 30px;
  }
}
.pricing-style-3 .pricing-item:before {
  background-color: #26212c;
  border-radius: 0 0 50% 50%;
  width: 370px;
  height: 222px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-30px);
  transition: all 0.3s ease 0s;
}
.pricing-style-3 .pricing-item:after {
  border: 1px solid #342e3b;
  border-radius: 0 0 50% 50%;
  position: absolute;
  content: "";
  top: 0;
  left: -10px;
  width: 390px;
  height: 242px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-30px);
  transition: all 0.3s ease 0s;
}
.pricing-style-3 .pricing-item:hover {
  background-color: #1f1927;
  border-color: #1f1927;
}
.pricing-style-3 .pricing-item:hover:before {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.pricing-style-3 .pricing-item:hover:after {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.pricing-style-3 .pricing-item:hover .pricing-info {
  background-color: #25b760;
}
.pricing-style-3 .pricing-item:hover .pricing-title {
  color: #ffffff;
}
.pricing-style-3 .pricing-item:hover .pricing-list li a {
  color: #ffffff;
}
.pricing-style-3 .pricing-item:hover .pricing-btn-wrap .btn-custom-size {
  background-color: #25b760;
  border-color: #25b760;
}
.pricing-style-3 .pricing-info {
  background-color: #25b760;
  border-radius: 30px;
}
.pricing-style-3 .pricing-info span {
  color: #ffffff;
}
.pricing-style-3 .pricing-list li a {
  color: #666f69;
}
.pricing-style-3 .pricing-btn-wrap .btn-custom-size {
  background-color: #f4fbf7;
  color: #25b760;
  border-color: #f4fbf7;
}
.pricing-style-4 .section-title {
  color: #500836;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .pricing-style-4 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.pricing-style-4 .section-desc {
  color: #534e4b;
  line-height: 42px;
}
.pricing-style-4 .pricing-tab-nav {
  background-color: #ffe4df;
}
.pricing-style-4 .pricing-tab-nav li a {
  font-family: "Gordita";
}
.pricing-style-4 .pricing-tab-nav li a.active {
  background-color: #ff8144;
  color: #ffffff;
}
.pricing-style-4 .pricing-item {
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  padding-left: 15px;
  border-color: #f6f1ee;
  transition: all 0.3s ease 0s;
}
@media (max-width: 991px) {
  .pricing-style-4 .pricing-item {
    margin-bottom: 30px;
  }
}
.pricing-style-4 .pricing-item:hover {
  border-color: #ff8144;
  background-color: #ff8144;
  transform: translateY(-25px);
}
.pricing-style-4 .pricing-item:hover:before {
  filter: opacity(0.3);
}
.pricing-style-4 .pricing-item:hover .pricing-info {
  background-color: #ffffff;
}
.pricing-style-4 .pricing-item:hover .pricing-info .price {
  color: #500836;
}
.pricing-style-4 .pricing-item:hover .pricing-info .month {
  color: #534e4b;
}
.pricing-style-4 .pricing-item:hover .pricing-title {
  color: #ffffff;
}
.pricing-style-4 .pricing-item:hover .pricing-list li a {
  color: #ffffff;
}
.pricing-style-4 .pricing-item:hover .pricing-btn-wrap .btn-custom-size {
  background-color: #ffffff;
  color: #ff8144;
  border-color: #ffffff;
}
.pricing-style-4 .pricing-item:before {
  content: url("../images/testimonial/shape/1.png");
  position: absolute;
  top: -40px;
  right: -35px;
  filter: opacity(1);
  transition: all 0.3s ease 0s;
  z-index: -1;
}
.pricing-style-4 .pricing-info {
  margin-left: 0;
  border-radius: 30px 10px 145px 10px;
  width: 167.5px;
  height: 167.5px;
  background-color: #ff8144;
  align-items: flex-start;
}
.pricing-style-4 .pricing-info span {
  color: #ffffff;
}
.pricing-style-4 .pricing-info span.price {
  margin-left: 30px;
}
.pricing-style-4 .pricing-info span.month {
  margin-left: 60px;
}
.pricing-style-4 .pricing-btn-wrap .btn-custom-size {
  background-color: #ffe4df;
  color: #ff8144;
  border-color: #ffe4df;
}
.pricing-style-5 .section-title {
  color: #07135b;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 991px) {
  .pricing-style-5 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.pricing-style-5 .section-desc {
  margin-bottom: 65px;
  color: #534e4b;
}
.pricing-style-5 .pricing-tab-nav {
  background-color: #f4f5ff;
}
.pricing-style-5 .pricing-tab-nav li a.active {
  background-color: #1a2c99;
  color: #ffffff;
}
.pricing-style-5 .pricing-item {
  padding-top: 0;
  overflow: hidden;
}
@media (max-width: 991px) {
  .pricing-style-5 .pricing-item {
    margin-top: 30px;
  }
}
.pricing-style-5 .pricing-item:hover {
  background-color: #1a2c99;
  border-color: #1a2c99;
}
.pricing-style-5 .pricing-item:hover .pricing-info {
  background-color: transparent;
}
.pricing-style-5 .pricing-item:hover .pricing-info:before {
  border-bottom: 135px solid #ffffff;
}
.pricing-style-5 .pricing-item:hover .pricing-info:after {
  border-bottom: 155px solid #2738a2;
}
.pricing-style-5 .pricing-item:hover .pricing-info span {
  color: #07135b;
}
.pricing-style-5 .pricing-item:hover .pricing-title {
  color: #ffffff;
}
.pricing-style-5 .pricing-item:hover .pricing-list li a {
  color: #ffffff;
}
.pricing-style-5 .pricing-item:hover .pricing-btn-wrap .btn-custom-size {
  background-color: #f0582e;
  border-color: #f0582e;
  color: #ffffff;
}
.pricing-style-5 .pricing-info {
  background-color: transparent;
  position: relative;
  z-index: 1;
}
.pricing-style-5 .pricing-info:before {
  border-bottom: 135px solid #1a2c99;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  height: 0;
  width: 275px;
  content: "";
  position: absolute;
  border-radius: 0 0 50% 50%;
  z-index: 1;
  bottom: 15px;
  transition: all 0.3s ease 0s;
}
.pricing-style-5 .pricing-info:after {
  border-bottom: 155px solid #f4f5ff;
  border-left: 105px solid transparent;
  border-right: 105px solid transparent;
  height: 0;
  width: 330px;
  content: "";
  position: absolute;
  border-radius: 0 0 50% 50%;
  z-index: -1;
  bottom: 0;
  transition: all 0.3s ease 0s;
}
.pricing-style-5 .pricing-info span {
  color: #ffffff;
  z-index: 2;
}
.pricing-style-5 .pricing-btn-wrap .btn-custom-size {
  background-color: #f4f5ff;
  border-color: #f4f5ff;
  color: #f0582e;
}

/*----------------------------------------*/
/* 09. Testimonial
/*----------------------------------------*/
.testimonial-area {
  padding-top: 110px;
}
.testimonial-area.with-background {
  background-color: #f5f8fb;
  padding-bottom: 135px;
}
.testimonial-area .section-style-2 {
  padding-bottom: 70px;
}
.testimonial-space-n120 {
  margin-top: -120px;
}
.testimonial-title {
  font-size: 65px;
}
@media (max-width: 991px) {
  .testimonial-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .testimonial-title {
    font-size: 35px;
  }
}
@media (min-width: 1400px) {
  .testimonial-container {
    max-width: calc(100% - 365px);
    margin-left: auto;
    margin-right: 0;
    padding-left: 0;
    padding-right: 40px;
  }
}
@media (min-width: 1400px) {
  .testimonial-container-2 {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-container-2 {
    max-width: 100%;
  }
}
.testimonial-item {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 50px;
}
@media (max-width: 767px) {
  .testimonial-item {
    padding: 25px;
  }
}
.testimonial-item:hover .testimonial-comment {
  border-color: #407ff0;
}
.testimonial-review {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .testimonial-review {
    flex-wrap: wrap;
  }
  .testimonial-review .quote {
    margin-bottom: 15px;
  }
}
.testimonial-review .rating-box ul {
  display: flex;
}
.testimonial-review .rating-box ul li:not(:last-child) {
  margin-right: 10px;
}
.testimonial-review .rating-box ul li i {
  color: #ffae00;
  font-size: 20px;
}
.testimonial-comment {
  border-bottom: 2px solid rgb(227, 232, 241);
  transition: all 0.3s ease 0s;
  padding-bottom: 35px;
  margin-bottom: 0;
  color: #57617b;
  font-size: 18px;
  line-height: 35px;
}
.testimonial-comment .quote {
  color: #407ff0;
}
.testimonial-user-info {
  margin-top: 30px;
  display: flex;
}
.testimonial-user-info .user-content {
  margin-left: 30px;
}
.testimonial-user-info .user-title {
  margin-bottom: 15px;
  font-size: 27px;
}
@media (max-width: 767px) {
  .testimonial-user-info .user-title {
    font-size: 18px;
  }
}
.testimonial-user-info .user-occupation {
  color: #57617b;
  font-size: 17px;
}
@media (max-width: 767px) {
  .testimonial-user-info .user-occupation {
    font-size: 14px;
  }
}
.testimonial-style-2 {
  padding-bottom: 120px;
}
.testimonial-style-2 .testimonial-item {
  box-shadow: 0px 0px 75px 0px rgba(47, 43, 84, 0.06);
  padding: 40px 60px;
  position: relative;
}
@media (max-width: 479px) {
  .testimonial-style-2 .testimonial-item {
    padding: 40px 20px;
  }
}
.testimonial-style-2 .testimonial-item:before {
  content: url("../images/testimonial/quote/2-1-144x102.png");
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
}
.testimonial-style-2 .testimonial-comment {
  border-bottom: 0;
  padding-bottom: 0;
  font-size: 18px;
  line-height: 35px;
}
.testimonial-style-2 .testimonial-user-info {
  margin-top: 35px;
}
.testimonial-style-2 .testimonial-user-info .user-title {
  margin-bottom: 10px;
  color: #2f2b54;
  font-size: 25px;
}
@media (max-width: 479px) {
  .testimonial-style-2 .testimonial-user-info .user-title {
    font-size: 20px;
  }
}
.testimonial-style-2 .testimonial-user-info .user-occupation {
  margin-bottom: 10px;
  font-size: 16px;
}
.testimonial-style-2 .testimonial-review .rating-box ul li i {
  font-size: 17px;
}
.testimonial-style-2 .testimonial-btn-wrap {
  padding-top: 90px;
  display: flex;
  justify-content: center;
}
.testimonial-style-3 {
  padding-top: 135px;
}
.testimonial-style-3 .section-title {
  color: #ffffff;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .testimonial-style-3 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.testimonial-style-3 .section-desc {
  color: #ffffff;
  line-height: 42px;
}
.testimonial-style-3 .testimonial-container {
  padding-bottom: 140px;
}
@media (min-width: 1400px) {
  .testimonial-style-3 .testimonial-container {
    max-width: calc(100% - 220px);
  }
}
.testimonial-style-3 .testimonial-item {
  box-shadow: 0px 0px 75px 0px rgba(47, 43, 84, 0.06);
  padding: 50px 35px 45px;
}
.testimonial-style-3 .testimonial-comment {
  position: relative;
  border-bottom: 0;
  padding-bottom: 35px;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
}
.testimonial-style-3 .testimonial-comment:before {
  content: url("../images/testimonial/quote/2-1-144x102.png");
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.testimonial-style-3 .testimonial-user-info {
  margin-top: 0;
  margin-bottom: 40px;
  justify-content: center;
}
.testimonial-style-3 .testimonial-user-info .user-title {
  margin-bottom: 10px;
  color: #2f2b54;
  font-size: 25px;
}
.testimonial-style-3 .testimonial-user-info .user-occupation {
  color: #25b760;
  margin-bottom: 10px;
  font-size: 16px;
}
.testimonial-style-3 .testimonial-review {
  justify-content: center;
  margin-bottom: 0;
}
.testimonial-style-3 .testimonial-review .rating-box ul li i {
  font-size: 20px;
}
.testimonial-style-3 .testimonial-btn-wrap {
  padding-top: 90px;
  display: flex;
  justify-content: center;
}
.testimonial-style-4 {
  padding-bottom: 140px;
}
.testimonial-style-4 .section-title {
  color: #500836;
  font-size: 55px;
  line-height: 70px;
  max-width: 795px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .testimonial-style-4 .section-title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .testimonial-style-4 .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.testimonial-style-4 .section-title span {
  color: #ff8144;
}
.testimonial-style-4 .section-desc {
  color: #534e4b;
  line-height: 42px;
  max-width: 100%;
}
.testimonial-style-4 .testimonial-slider-4 {
  padding-top: 65px;
}
@media (max-width: 1199px) {
  .testimonial-style-4 .testimonial-button-prev, .testimonial-style-4 .testimonial-button-next {
    display: none;
  }
}
.testimonial-style-4 .testimonial-item {
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1200px) {
  .testimonial-style-4 .testimonial-item {
    max-width: 1080px;
  }
}
.testimonial-style-4 .testimonial-user-info {
  flex-direction: column;
  margin-top: 0;
  position: relative;
  top: -65px;
}
.testimonial-style-4 .testimonial-user-info .user-img img {
  border: 5px solid #ff8144;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .testimonial-style-4 .testimonial-user-info .user-content {
    margin-left: 0;
  }
}
.testimonial-style-4 .testimonial-user-info .user-comment {
  color: #534e4b;
  font-size: 25px;
  line-height: 41px;
  position: relative;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .testimonial-style-4 .testimonial-user-info .user-comment {
    font-size: 20px;
  }
}
.testimonial-style-4 .testimonial-user-info .user-comment:before {
  content: url("../images/testimonial/quote/2-1-144x102.png");
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.testimonial-style-4 .testimonial-user-info .user-title {
  color: #500836;
  font-size: 30px;
}
@media (max-width: 575px) {
  .testimonial-style-4 .testimonial-user-info .user-title {
    font-size: 25px;
  }
}
.testimonial-style-4 .testimonial-user-info .user-occupation {
  color: #ff8144;
  margin-bottom: 0;
}
.testimonial-style-4 .testimonial-review {
  justify-content: center;
  margin-top: 40px;
}
.testimonial-style-5 {
  padding-bottom: 280px;
}
.testimonial-style-5 .testimonial-title {
  color: #07135b;
  max-width: 750px;
  margin-left: 0;
}
.testimonial-style-5 .testimonial-title span {
  color: #f0582e;
}
.testimonial-style-5 .testimonial-slider-5 {
  padding-top: 65px;
}
.testimonial-style-5 .testimonial-item {
  text-align: center;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease 0s;
}
.testimonial-style-5 .testimonial-item:hover {
  background-color: #1a2c99;
}
.testimonial-style-5 .testimonial-item:hover .testimonial-user-info .user-comment, .testimonial-style-5 .testimonial-item:hover .testimonial-user-info .user-title {
  color: #ffffff;
}
.testimonial-style-5 .testimonial-item:hover .testimonial-user-info .user-occupation {
  color: #f0582e;
}
.testimonial-style-5 .testimonial-user-info {
  flex-direction: column;
  margin-top: 0;
  position: relative;
  top: -65px;
}
.testimonial-style-5 .testimonial-user-info .user-content {
  margin-left: 0;
}
.testimonial-style-5 .testimonial-user-info .user-img img {
  border-radius: 50%;
}
.testimonial-style-5 .testimonial-user-info .user-comment {
  color: #534e4b;
  font-size: 25px;
  line-height: 41px;
  position: relative;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .testimonial-style-5 .testimonial-user-info .user-comment {
    font-size: 20px;
  }
}
.testimonial-style-5 .testimonial-user-info .user-comment:before {
  content: url("../images/testimonial/quote/2-1-144x102.png");
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.testimonial-style-5 .testimonial-user-info .user-title {
  color: #500836;
  font-size: 30px;
}
@media (max-width: 767px) {
  .testimonial-style-5 .testimonial-user-info .user-title {
    font-size: 25px;
  }
}
.testimonial-style-5 .testimonial-user-info .user-occupation {
  color: #ff8144;
  margin-bottom: 0;
}
.testimonial-style-5 .testimonial-review {
  justify-content: center;
  margin-top: 40px;
}
.testimonial-style-5 .testimonial-arrow-wrap .testimonial-button-prev i, .testimonial-style-5 .testimonial-arrow-wrap .testimonial-button-next i {
  border-color: #07135b;
  color: #07135b;
}
.testimonial-style-5 .testimonial-arrow-wrap .testimonial-button-prev i:hover, .testimonial-style-5 .testimonial-arrow-wrap .testimonial-button-next i:hover {
  background-color: #f0582e;
  border-color: #f0582e;
  color: #ffffff;
}

/*----------------------------------------*/
/*  10. Footer
/*----------------------------------------*/
@media (min-width: 1400px) {
  .footer-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .footer-container {
    max-width: 100%;
  }
}
.footer-upper-top {
  display: flex;
  justify-content: center;
  padding-top: 80px;
}
.footer-upper-top .footer-logo {
  margin-bottom: 0;
}
.footer-top {
  padding-top: 140px;
  padding-bottom: 140px;
}
.footer-logo {
  margin-bottom: 55px;
  display: block;
}
.footer-desc {
  color: #57617b;
  max-width: 295px;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 50px;
}
@media (max-width: 991px) {
  .footer-desc {
    max-width: 520px;
  }
}
.footer-social-link ul {
  display: flex;
}
.footer-social-link ul li:not(:last-child) {
  margin-right: 20px;
}
.footer-social-link ul li a i {
  border: 2px solid rgb(227, 232, 241);
  transition: all 0.3s ease 0s;
  border-radius: 50%;
  text-align: center;
  color: #042659;
  font-size: 14px;
  width: 60px;
  height: 60px;
  line-height: 59px;
  display: block;
}
.footer-social-link ul li a i:hover {
  background-color: #407ff0;
  border-color: #407ff0;
  color: #ffffff;
}
.footer-list .title {
  margin-bottom: 35px;
  font-size: 30px;
}
@media (max-width: 1199px) {
  .footer-list .title {
    font-size: 27px;
  }
}
.footer-list ul li a {
  color: #57617b;
  font-weight: 500;
  font-size: 19px;
  line-height: 55px;
}
.footer-list ul li a:hover {
  color: #407ff0;
}
.footer-bottom-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (max-width: 991px) {
  .footer-bottom-item {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer-bottom-item.with-border:before {
  background-color: rgb(232, 238, 244);
  opacity: 1;
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 575px) {
  .footer-bottom-item .btn-group {
    flex-direction: column;
  }
}
.footer-bottom-item .btn-group .title {
  margin-right: 30px;
  color: #57617b;
  font-weight: 500;
  font-size: 19px;
  align-self: center;
}
@media (max-width: 575px) {
  .footer-bottom-item .btn-group .title {
    margin-right: 0;
  }
}
.footer-bottom-item .btn-group a:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 575px) {
  .footer-bottom-item .btn-group a:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 575px) {
  .footer-bottom-item .btn-group a {
    width: 100%;
    margin-top: 20px;
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .footer-copyright {
    padding-top: 15px;
    text-align: center;
  }
}
.footer-copyright span {
  font-size: 18px;
}
.footer-copyright span i {
  color: #407ff0;
}
.footer-copyright.white-text span,
.footer-copyright.white-text a {
  color: #ffffff;
}
@media (min-width: 1200px) {
  .footer-ps-xl-30 {
    padding-left: 30px;
  }
}
@media (min-width: 1200px) {
  .footer-ps-xl-20 {
    padding-left: 20px;
  }
}
.footer-style-2.footer-space-140 {
  padding-top: 140px;
}
@media (min-width: 1400px) {
  .footer-style-2 .footer-container {
    max-width: 1350px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .footer-style-2 .footer-container {
    max-width: 100%;
  }
}
.footer-style-2 .footer-top {
  padding-bottom: 95px;
}
@media (max-width: 991px) {
  .footer-style-2 .footer-item {
    margin-top: 50px;
  }
}
@media (min-width: 1400px) {
  .footer-style-2 .footer-item-wrap {
    --bs-gutter-x: 70px;
  }
  .footer-style-2 .footer-item-wrap [class*=col-] {
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .footer-style-2 .footer-item-wrap .footer-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.footer-style-2 .footer-desc {
  color: #d8dadf;
}
.footer-style-2 .footer-list .title {
  color: #ffffff;
}
@media (max-width: 1399px) {
  .footer-style-2 .footer-list .title {
    font-size: 27px;
  }
}
.footer-style-2 .footer-list ul li a {
  color: #d8dadf;
}
.footer-style-2 .footer-list ul li a:hover {
  color: #fe6a21;
}
.footer-style-2 .footer-social-link li a i {
  color: #ffffff;
}
.footer-style-2 .footer-social-link li a i:hover {
  background-color: #fe6a21;
  border-color: #fe6a21;
}
.footer-style-2 .footer-logo {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .footer-style-2 .footer-logo {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .footer-style-2 .footer-logo {
    margin-bottom: 20px;
  }
}
.footer-style-2 .footer-bottom-item:before {
  opacity: 0.07;
  height: 1px;
}
.footer-style-2 .footer-copyright span a,
.footer-style-2 .footer-copyright span i {
  color: #fe6a21;
}
.footer-style-3 {
  background-position: center;
  position: relative;
}
@media (max-width: 991px) {
  .footer-style-3 .footer-top {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .footer-style-3 .footer-top {
    padding-top: 25px;
  }
}
@media (max-width: 991px) {
  .footer-style-3 .footer-item {
    margin-top: 50px;
  }
}
.footer-style-3 .footer-list .title {
  color: #ffffff;
}
.footer-style-3 .footer-list ul li a {
  color: #d8dadf;
}
.footer-style-3 .footer-list ul li a:hover {
  color: #25b760;
}
.footer-style-3 .footer-desc {
  color: #d8dadf;
}
.footer-style-3 .footer-social-link ul li a i {
  color: #ffffff;
}
.footer-style-3 .footer-social-link ul li a i:hover {
  background-color: #25b760;
  border-color: #25b760;
}
@media (max-width: 991px) {
  .footer-style-3 .footer-copyright {
    margin-bottom: 20px;
  }
}
.footer-style-3 .footer-copyright a,
.footer-style-3 .footer-copyright i {
  color: #25b760;
}
.footer-style-3 .footer-inner-img {
  position: absolute;
  bottom: 90px;
  left: 60px;
}
@media (max-width: 1399px) {
  .footer-style-3 .footer-inner-img {
    bottom: auto;
    top: 30px;
    left: 30px;
  }
}
@media (max-width: 991px) {
  .footer-style-3 .footer-inner-img {
    display: none;
  }
}
.footer-style-4 .footer-upper-top-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 80px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer-style-4 .footer-upper-top-item {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .footer-style-4 .footer-upper-top-item {
    flex-direction: column;
  }
}
.footer-style-4 .footer-upper-top-item:before {
  background-color: #ffffff;
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.071;
}
@media (max-width: 767px) {
  .footer-style-4 .footer-upper-top-item .footer-logo {
    margin-bottom: 30px;
  }
}
.footer-style-4 .footer-upper-top-item .footer-btn-wrap {
  align-items: center;
}
@media (max-width: 991px) {
  .footer-style-4 .footer-upper-top-item .footer-btn-wrap {
    margin-top: 25px;
  }
}
@media (max-width: 767px) {
  .footer-style-4 .footer-upper-top-item .footer-btn-wrap {
    text-align: center;
  }
}
.footer-style-4 .footer-upper-top-item .footer-btn-wrap .title {
  color: #d8dadf;
  font-size: 19px;
  margin-right: 15px;
  text-align: center;
}
@media (max-width: 767px) {
  .footer-style-4 .footer-upper-top-item .footer-btn-wrap a {
    margin-top: 15px;
    display: block;
  }
}
.footer-style-4 .footer-upper-top-item .footer-btn-wrap a:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 767px) {
  .footer-style-4 .footer-upper-top-item .footer-btn-wrap a:not(:last-child) {
    margin-right: 0;
  }
}
.footer-style-4 .footer-top {
  padding-top: 100px;
  padding-bottom: 130px;
}
@media (max-width: 991px) {
  .footer-style-4 .footer-item {
    margin-top: 50px;
  }
}
.footer-style-4 .footer-list .title {
  color: #ffffff;
}
.footer-style-4 .footer-list ul li a {
  color: #d8dadf;
  font-size: 18px;
}
.footer-style-4 .footer-list ul li a:hover {
  color: #ff8144;
}
.footer-style-4 .footer-list.contact-list ul li {
  display: flex;
  align-items: baseline;
}
.footer-style-4 .footer-list.contact-list ul li i {
  color: #ff8144;
  margin-right: 15px;
  width: 17px;
}
.footer-style-4 .footer-list.contact-list ul li span {
  color: #d8dadf;
}
.footer-style-4 .footer-list.contact-list ul li a,
.footer-style-4 .footer-list.contact-list ul li span {
  width: calc(100% - 17px);
  line-height: 45px;
}
.footer-style-4 .footer-desc {
  color: #d8dadf;
}
.footer-style-4 .footer-social-link ul li a i {
  color: #ffffff;
}
.footer-style-4 .footer-social-link ul li a i:hover {
  background-color: #ff8144;
  border-color: #ff8144;
}
.footer-style-4 .footer-bottom {
  background-color: #480931;
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-style-4 .footer-bottom-item {
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
}
.footer-style-4 .footer-copyright a,
.footer-style-4 .footer-copyright i {
  color: #ff8144;
}
.footer-style-5 {
  background-color: #07114f;
}
.footer-style-5 .footer-top {
  padding-top: 135px;
  padding-bottom: 90px;
}
.footer-style-5 .footer-item {
  margin-bottom: 30px;
}
.footer-style-5 .footer-list .title {
  color: #ffffff;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .footer-style-5 .footer-list .title {
    font-size: 27px;
  }
}
.footer-style-5 .footer-list ul li a {
  color: #d8dadf;
  font-size: 18px;
}
.footer-style-5 .footer-list ul li a:hover {
  color: #ff8144;
}
.footer-style-5 .footer-list.contact-list ul li {
  display: flex;
  align-items: baseline;
}
.footer-style-5 .footer-list.contact-list ul li i {
  color: #ff8144;
  margin-right: 15px;
  width: 17px;
}
.footer-style-5 .footer-list.contact-list ul li span {
  color: #d8dadf;
}
.footer-style-5 .footer-list.contact-list ul li a,
.footer-style-5 .footer-list.contact-list ul li span {
  width: calc(100% - 17px);
  line-height: 45px;
}
.footer-style-5 .footer-desc {
  color: #d8dadf;
}
.footer-style-5 .footer-social-link ul li a i {
  color: #ffffff;
}
.footer-style-5 .footer-social-link ul li a i:hover {
  background-color: #f0582e;
  border-color: #f0582e;
}
.footer-style-5 .footer-upper-bottom-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0e1a63;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .footer-style-5 .footer-upper-bottom-item {
    flex-direction: column;
  }
}
.footer-style-5 .footer-upper-bottom-item .footer-logo {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .footer-style-5 .footer-upper-bottom-item .footer-logo {
    margin-bottom: 15px;
  }
}
.footer-style-5 .footer-upper-bottom-item .footer-btn-wrap {
  align-items: center;
}
.footer-style-5 .footer-upper-bottom-item .footer-btn-wrap .title {
  color: #d8dadf;
  font-size: 19px;
  margin-right: 15px;
}
.footer-style-5 .footer-upper-bottom-item .footer-btn-wrap a:not(:last-child) {
  margin-right: 15px;
}
@media (max-width: 767px) {
  .footer-style-5 .footer-scroll-top {
    margin-bottom: 15px;
  }
}
.footer-style-5 .footer-scroll-top i {
  background-color: #f0582e;
  border-radius: 50%;
  color: #ffffff;
  width: 55px;
  height: 55px;
  line-height: 55px;
  display: block;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.footer-style-5 .footer-scroll-top i:hover {
  background-color: #1f1927;
}
.footer-style-5 .footer-bottom {
  background-color: #07114f;
  padding-top: 35px;
  padding-bottom: 25px;
}
.footer-style-5 .footer-bottom-item {
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 479px) {
  .footer-style-5 .footer-copyright span {
    font-size: 14px;
  }
}
.footer-style-5 .footer-copyright a,
.footer-style-5 .footer-copyright i {
  color: #f0582e;
}

/*----------------------------------------*/
/*  11. Animation
/*----------------------------------------*/
/* ---Wave Button--- */
@keyframes wave-button {
  0% {
    opacity: 0.5;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.3;
    transform: scale(0.6);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
.wave-btn {
  margin: auto;
  display: block;
  position: relative;
  width: 250px;
  height: 250px;
}
.wave-btn > .icon {
  background-color: #407ff0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 24px;
}
.wave-btn span {
  background-color: rgb(255, 255, 255);
  position: absolute;
  border-radius: 50%;
  width: 250px;
  height: 250px;
}
.wave-btn span:nth-child(1) {
  background-color: rgb(255, 255, 255);
  animation: wave-button 2s infinite 0.1s linear backwards;
}
.wave-btn span:nth-child(2) {
  background-color: rgb(255, 255, 255);
  animation: wave-button 3s infinite 0.5s linear backwards;
}
.wave-btn span:nth-child(3) {
  background-color: rgb(255, 255, 255);
  animation: wave-button 4s infinite 1s linear backwards;
}
.wave-btn.style-2 > .icon {
  background-color: #fe6a21;
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.wave-btn.style-3 > .icon {
  background-color: #25b760;
  width: 100px;
  height: 100px;
  line-height: 100px;
}

/*----------------------------------------*/
/*  12. Intro
/*----------------------------------------*/
.intro-area .section-title {
  color: #2f2b54;
  font-size: 55px;
  line-height: 70px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .intro-area .section-title {
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 10px;
  }
}
.intro-area .section-desc {
  line-height: 42px;
  margin-bottom: 75px;
}
@media (max-width: 767px) {
  .intro-area .section-desc {
    font-size: 18px;
  }
}
.intro-item.intro-bg {
  background-color: transparent;
  height: 600px;
}
.intro-item .popup-btn {
  display: flex;
  height: 100%;
}

/*----------------------------------------*/
/*  13. Screen
/*----------------------------------------*/
.screen-area {
  padding-top: 435px;
}
.screen-area .section-title {
  color: #2f2b54;
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 767px) {
  .screen-area .section-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.screen-bg {
  background-position: bottom;
}
@media (max-width: 1199px) {
  .screen-bg {
    background-position: right;
  }
}
@media (min-width: 1400px) {
  .screen-container {
    max-width: 1500px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .screen-container {
    max-width: 100%;
  }
}
.screen-slider {
  margin-top: -10px;
  margin-bottom: -10px;
}
.screen-slider .screen-item {
  margin-top: 10px;
  margin-bottom: 10px;
}
.screen-item {
  border: 2px solid transparent;
  transition: all 0.3s ease 0s;
  border-radius: 32px;
  padding: 20px;
}
.screen-item:hover {
  border-color: #fe6a21;
}
@media (max-width: 767px) {
  .screen-item {
    border: 0;
  }
}
.screen-img {
  display: block;
}
.screen-arrow-wrap {
  padding-top: 80px;
  display: flex;
  justify-content: center;
}
.screen-arrow-wrap .screen-button-prev i:hover, .screen-arrow-wrap .screen-button-next i:hover {
  background-color: #fe6a21;
  border-color: #fe6a21;
}

/*----------------------------------------*/
/* 14. Newsletter
/*----------------------------------------*/
.newsletter-area.style-1 * {
  color: #ffffff;
}
@media (max-width: 991px) {
  .newsletter-area.style-1 .newsletter-item .inner-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-1 .newsletter-item .inner-item .btn-custom-size {
    line-height: 67px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-1 .newsletter-item .inner-item .btn-wrap {
    position: relative;
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-1 .newsletter-form {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .newsletter-area.style-1 .newsletter-form .input-field {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-1 .newsletter-form .input-field {
    padding-right: 35px;
  }
}
.newsletter-area.style-2 {
  margin-bottom: 140px;
}
@media (max-width: 991px) {
  .newsletter-area.style-2 .newsletter-item .inner-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.newsletter-area.style-2 .newsletter-title {
  color: #1f1927;
}
.newsletter-area.style-2 .newsletter-desc {
  color: #6f7872;
}
.newsletter-area.style-2 .newsletter-form .input-field {
  border-color: rgb(227, 234, 229);
  background-color: transparent;
  color: #5f6762;
}
@media (max-width: 767px) {
  .newsletter-area.style-2 .newsletter-form .input-field {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-2 .newsletter-form .input-field {
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-2 .newsletter-form .btn-custom-size {
    line-height: 67px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-2 .newsletter-form .btn-wrap {
    position: relative;
    margin-top: 15px;
  }
}
.newsletter-area.style-3 {
  padding-bottom: 140px;
}
.newsletter-area.style-3 .newsletter-bg {
  border-radius: 25px;
}
.newsletter-area.style-3 .newsletter-item {
  padding-top: 70px;
  padding-bottom: 55px;
  text-align: left;
  margin-left: 75px;
}
@media (max-width: 767px) {
  .newsletter-area.style-3 .newsletter-item {
    text-align: center;
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .newsletter-area.style-3 .newsletter-item .inner-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.newsletter-area.style-3 .newsletter-title {
  color: #500836;
}
.newsletter-area.style-3 .newsletter-form .input-field {
  border: 2px solid #ecd1cc;
  background-color: #ffe4df;
  color: #534e4b;
  width: 570px;
}
@media (max-width: 767px) {
  .newsletter-area.style-3 .newsletter-form .input-field {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-3 .newsletter-form .input-field {
    padding-right: 35px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-3 .newsletter-form .btn-custom-size {
    line-height: 67px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-3 .newsletter-form .btn-wrap {
    position: relative;
    margin-top: 15px;
  }
}
.newsletter-area.style-4.newsletter-space-y-axis {
  padding-top: 140px;
  padding-bottom: 140px;
}
.newsletter-area.style-4 * {
  color: #ffffff;
}
@media (max-width: 767px) {
  .newsletter-area.style-4 .newsletter-item .inner-item {
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-4 .newsletter-item .inner-item .btn-custom-size {
    line-height: 67px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-4 .newsletter-item .inner-item .btn-wrap {
    position: relative;
    margin-top: 15px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-4 .newsletter-form {
    width: 100%;
  }
}
.newsletter-area.style-4 .newsletter-form .input-field {
  background-color: transparent;
  border-color: #ffffff;
  width: 570px;
}
@media (max-width: 767px) {
  .newsletter-area.style-4 .newsletter-form .input-field {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-4 .newsletter-form .input-field {
    padding-right: 35px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-4 .newsletter-form .btn-custom-size {
    line-height: 67px;
  }
}
@media (max-width: 575px) {
  .newsletter-area.style-4 .newsletter-form .btn-wrap {
    position: relative;
    margin-top: 15px;
  }
}
.newsletter-item {
  padding-top: 65px;
  padding-bottom: 65px;
  text-align: center;
  border-radius: 25px;
}
.newsletter-title {
  font-size: 55px;
  line-height: 70px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .newsletter-title {
    font-size: 35px;
    line-height: 50px;
  }
}
.newsletter-desc {
  font-size: 24px;
  line-height: 42px;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .newsletter-desc {
    font-size: 20px;
    line-height: 30px;
  }
}
.newsletter-form {
  display: inline-block;
  position: relative;
}
.newsletter-form .input-field {
  background-color: #fe8549;
  border: 1px solid #fe8549;
  border-radius: 20px;
  color: #fefdfc;
  font-size: 17px;
  width: 630px;
  height: 70px;
  padding-left: 30px;
  padding-right: 240px;
  margin-left: auto;
  margin-right: auto;
}
.newsletter-form .btn-wrap {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.newsletter-form .btn-custom-size {
  border-radius: 20px;
  line-height: 1;
  height: 100%;
}
.newsletter-space-top-n140 {
  margin-top: -140px;
}
.newsletter-space-n140 {
  margin-bottom: -140px;
}

/* ---Mailchimp--- */
.mailchimp-submitting, .mailchimp-success, .mailchimp-error {
  margin-top: 25px;
}

/*----------------------------------------*/
/*  15. Trial
/*----------------------------------------*/
.trial-style-1 {
  padding-top: 115px;
  padding-bottom: 140px;
}
.trial-style-2 {
  padding-top: 135px;
  padding-bottom: 140px;
}
.trial-style-2 .trial-category {
  color: #f0582e;
}
.trial-style-2 .trial-title {
  color: #07135b;
}
.trial-style-2 .trial-desc {
  color: #4a4b51;
  margin-bottom: 45px;
}
.trial-item {
  text-align: center;
}
.trial-category {
  color: #ff8144;
  margin-bottom: 25px;
  font-size: 23px;
  font-weight: 500;
  line-height: 28px;
  display: block;
}
.trial-title {
  color: #500836;
  font-size: 55px;
  line-height: 70px;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .trial-title {
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 20px;
  }
}
.trial-desc {
  color: #534e4b;
  font-size: 25px;
  line-height: 42px;
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .trial-desc {
    font-size: 20px;
  }
}
.trial-btn-wrap a:not(:last-child) {
  margin-right: 25px;
}
@media (max-width: 479px) {
  .trial-btn-wrap a:not(:last-child) {
    margin-bottom: 15px;
    margin-right: 0;
    display: block;
  }
}

/*----------------------------------------*/
/*  16. FAQ
/*----------------------------------------*/
.faq-style-common .section-title {
  font-size: 55px;
  line-height: 70px;
}
@media (max-width: 991px) {
  .faq-style-common .section-title {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .faq-style-common .section-title {
    font-size: 30px;
  }
}
.faq-style-common .section-desc {
  line-height: 42px;
}
.faq-style-common .faq-item {
  border-radius: 30px;
  padding-top: 35px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 25px;
  margin-bottom: 30px;
  transition: all 0.3s ease 0s;
}
@media (max-width: 575px) {
  .faq-style-common .faq-item {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.faq-style-common .faq-item:hover .faq-title {
  color: #ffffff;
}
.faq-style-common .faq-item:hover .faq-desc {
  color: #ffffff;
}
.faq-style-common .faq-title {
  margin-bottom: 25px;
  font-size: 26px;
  line-height: 1.4;
}
@media (max-width: 575px) {
  .faq-style-common .faq-title {
    font-size: 24px;
  }
}
.faq-style-common .faq-desc {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
}
.faq-style-common .faq-group-desc [class*=faq-desc]:not(:last-child) {
  margin-bottom: 15px;
}
.faq-style-common .faq-btn-wrap {
  padding-top: 70px;
  display: flex;
  justify-content: center;
}
.faq-style-1 {
  padding-top: 125px;
  padding-bottom: 140px;
}
.faq-style-1 .section-title {
  color: #500836;
}
.faq-style-1 .section-desc {
  color: #534e4b;
}
.faq-style-1 .faq-item {
  border: 2px solid rgb(246, 241, 238);
}
.faq-style-1 .faq-item:hover {
  border-color: #ff8144;
  background-color: #ff8144;
}
.faq-style-1 .faq-title {
  color: #500836;
}
.faq-style-1 .faq-desc {
  color: #534e4b;
}
.faq-style-2 {
  padding-top: 125px;
  padding-bottom: 140px;
}
.faq-style-2 .section-title {
  color: #07135b;
}
.faq-style-2 .section-desc {
  color: #534e4b;
}
.faq-style-2 .faq-item {
  border: 2px solid #f4f5ff;
}
.faq-style-2 .faq-item:hover {
  border-color: #1a2c99;
  background-color: #1a2c99;
}
.faq-style-2 .faq-title {
  color: #07135b;
}
.faq-style-2 .faq-desc {
  color: #534e4b;
}

/*----------------------------------------*/
/*  17. Breadcrumb
/*----------------------------------------*/
.breadcrumb-area {
  background-color: #1d2f9c;
}
.breadcrumb-height {
  height: 450px;
}
@media (max-width: 767px) {
  .breadcrumb-height {
    background-position: bottom;
  }
}
.breadcrumb-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.breadcrumb-title {
  color: #ffffff;
  font-size: 52px;
  line-height: 90px;
}
@media (max-width: 479px) {
  .breadcrumb-title {
    font-size: 40px;
  }
}
.breadcrumb-list li {
  display: inline-block;
}
.breadcrumb-list li:not(:last-child) {
  padding-right: 10px;
}
.breadcrumb-list li:not(:last-child) a {
  position: relative;
}
.breadcrumb-list li:not(:last-child) a:after {
  content: "-";
  margin-left: 15px;
}
.breadcrumb-list li a,
.breadcrumb-list li span {
  color: #ffffff;
  font-size: 20px;
  line-height: 45px;
}
.breadcrumb-list li span {
  color: #f0582e;
}

/*----------------------------------------*/
/*  18. Blog
/*----------------------------------------*/
.blog-img {
  display: block;
}
.blog-img img {
  border-radius: 15px;
  width: 100%;
}
.blog-content {
  padding-top: 35px;
}
.blog-category {
  font-family: "Gordita";
  color: #0f034a;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}
.blog-category span {
  color: #9b9ea1;
}
.blog-title {
  color: #081131;
  font-size: 23px;
  line-height: 35px;
  margin-bottom: 15px;
}
.blog-title a {
  color: #081131;
}
.blog-desc {
  font-size: 15px;
  line-height: 26px;
  margin-bottom: 10px;
}
.blog-meta span {
  font-family: "Gordita";
  color: #0f034a;
  font-weight: 500;
  font-size: 13px;
  line-height: 28px;
}
.blog-meta .time {
  position: relative;
  padding-left: 20px;
  margin-left: 8px;
}
.blog-meta .time:before {
  background-color: #0f034a;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.blog-listview .blog-item {
  display: flex;
}
@media (max-width: 767px) {
  .blog-listview .blog-item {
    flex-direction: column;
  }
}
.blog-listview .blog-img {
  width: 350px;
}
@media (max-width: 1199px) {
  .blog-listview .blog-img {
    width: 250px;
  }
}
@media (max-width: 767px) {
  .blog-listview .blog-img {
    width: 100%;
  }
}
.blog-listview .blog-content {
  width: calc(100% - 350px);
  padding-left: 30px;
  padding-top: 0;
  align-self: center;
}
@media (max-width: 1199px) {
  .blog-listview .blog-content {
    width: calc(100% - 250px);
  }
}
@media (max-width: 767px) {
  .blog-listview .blog-content {
    width: 100%;
    padding-left: 0;
    padding-top: 30px;
  }
}
.blog-detail {
  padding-top: 135px;
}
.blog-detail .blog-content {
  padding-top: 0;
  text-align: center;
}
@media (max-width: 767px) {
  .blog-detail .blog-content {
    margin-bottom: -20px;
  }
}
.blog-detail .blog-meta {
  margin-bottom: 20px;
}
.blog-detail .blog-meta span {
  font-family: "Gordita";
  color: #626262;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
}
.blog-detail .blog-meta span em {
  font-style: normal;
  color: #626262;
}
.blog-detail .blog-meta span.author {
  color: #091d40;
  font-weight: 500;
}
.blog-detail .blog-meta .common {
  position: relative;
  padding-left: 20px;
  margin-left: 8px;
}
.blog-detail .blog-meta .common:before {
  background-color: #0f034a;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.blog-detail .blog-title {
  max-width: 755px;
  color: #07135b;
  font-size: 32px;
  line-height: 47px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  margin-bottom: 30px;
}
@media (max-width: 479px) {
  .blog-detail .blog-title {
    font-size: 20px;
    line-height: 35px;
  }
}
.blog-detail .blog-tags {
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
@media (max-width: 767px) {
  .blog-detail .blog-tags {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .blog-detail .blog-tags li {
    margin-bottom: 20px;
  }
}
.blog-detail .blog-tags li:not(:last-child) {
  margin-right: 20px;
}
.blog-detail .blog-tags li a {
  border-radius: 8px;
  display: block;
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
}
.blog-detail .blog-tags li a.wordpress {
  background-color: #e0f9f6;
  color: #3ac8bd;
}
.blog-detail .blog-tags li a.woocommerce {
  background-color: #fff1dc;
  color: #d59a46;
}
.blog-detail .blog-tags li a.magento {
  background-color: #fbefef;
  color: #b36262;
}
.blog-detail .blog-tags li a.laravel {
  background-color: #f2efff;
  color: #9c8bda;
}
.blog-detail .blog-tags li a:hover {
  background-color: #f0582e;
  color: #ffffff;
}
.blog-detail-info {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}
@media (max-width: 991px) {
  .blog-detail-info {
    padding-left: 0;
    padding-right: 0;
  }
}
.blog-detail-info .title {
  color: #07135b;
  font-size: 25px;
  line-height: 40px;
  max-width: 915px;
  margin-bottom: 20px;
}
@media (max-width: 479px) {
  .blog-detail-info .title {
    font-size: 20px;
    line-height: 35px;
  }
}
.blog-detail-info .title.style-2 {
  max-width: 790px;
}
.blog-detail-info .desc {
  font-family: "Gordita";
  font-size: 15px;
  line-height: 28px;
}
.blog-detail-info .list-area {
  margin-bottom: 40px;
}
.blog-detail-info .list-area .title {
  margin-bottom: 40px;
}
.blog-detail-info .list-item li:not(:last-child) {
  margin-bottom: 10px;
}
.blog-detail-info .list-item li a {
  border: 1px solid #eeeeee;
  transition: all 0.3s ease 0s;
  font-family: "Gordita";
  border-radius: 10px;
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  color: #5138ee;
  font-weight: 500;
  display: block;
}
.blog-detail-info .list-item li a:hover {
  background-color: #efefef;
}
.blog-detail-info .list-item li a i {
  margin-right: 10px;
  color: #0a1e43;
  font-size: 16px;
}
.blog-detail-info .single-img {
  margin-top: 35px;
  margin-bottom: 35px;
}
.blog-detail-info .single-img img {
  border-radius: 15px;
}
.blog-detail-info .blog-quote {
  background-color: #1a2c99;
  padding-top: 65px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 65px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .blog-detail-info .blog-quote {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.blog-detail-info .blog-quote .title {
  font-size: 44px;
  line-height: 68px;
  color: #ffffff;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .blog-detail-info .blog-quote .title {
    font-size: 35px;
    line-height: 50px;
  }
}
@media (max-width: 767px) {
  .blog-detail-info .blog-quote .title {
    font-size: 25px;
    line-height: 40px;
  }
}
@media (max-width: 479px) {
  .blog-detail-info .blog-quote .title {
    font-size: 20px;
  }
}
.blog-detail-info .blog-social-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  padding-top: 25px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 25px;
  margin-top: 30px;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .blog-detail-info .blog-social-link {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 479px) {
  .blog-detail-info .blog-social-link {
    flex-direction: column;
  }
}
.blog-detail-info .blog-social-link .title {
  color: #0a1e43;
  font-size: 19px;
  line-height: 35px;
  margin-bottom: 0;
}
@media (max-width: 479px) {
  .blog-detail-info .blog-social-link .title {
    margin-bottom: 10px;
  }
}
.blog-detail-info .blog-social-link .social-list {
  display: flex;
}
.blog-detail-info .blog-social-link .social-list li:not(:last-child) {
  margin-right: 20px;
}
.blog-detail-info .blog-social-link .social-list li a {
  background-color: #f4f4f4;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  display: block;
  transition: all 0.3s ease 0s;
}
.blog-detail-info .blog-social-link .social-list li a:hover {
  background-color: #5138ee;
}
.blog-detail-info .blog-social-link .social-list li a:hover i {
  color: #ffffff;
}
.blog-detail-info .blog-social-link .social-list li a i {
  color: #22262a;
  font-size: 14px;
}
.blog-slider-area {
  background-color: #f8f8f8;
  padding-top: 75px;
  padding-bottom: 70px;
}
.blog-slider-area .section-area {
  margin-bottom: 20px;
}
.blog-slider-area .section-title {
  color: #07135b;
  font-size: 38px;
  line-height: 95px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .blog-slider-area .section-title {
    font-size: 30px;
  }
}
.blog-comment-area {
  padding-top: 55px;
}
@media (min-width: 992px) {
  .blog-comment-wrap {
    padding-left: 100px;
    padding-right: 100px;
  }
}
.blog-comment-title {
  color: #0a1e43;
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 35px;
}
.blog-comment-item {
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 20px;
}
.blog-comment-item.reply-item {
  margin-left: 100px;
}
@media (max-width: 767px) {
  .blog-comment-item.reply-item {
    margin-left: 25px;
  }
}
.blog-comment-item[class*=blog-comment-item]:not(:last-child) {
  margin-bottom: 20px;
}
.blog-comment-item .inner-item {
  display: flex;
}
@media (max-width: 575px) {
  .blog-comment-item .inner-item {
    flex-direction: column;
  }
}
.blog-comment-item .comment-img {
  width: 90px;
}
.blog-comment-item .comment-content {
  margin-left: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 90px);
}
@media (max-width: 575px) {
  .blog-comment-item .comment-content {
    margin-top: 30px;
    margin-left: 0;
    width: 100%;
  }
}
.blog-comment-item .comment-content .title {
  color: #0a1e43;
  font-size: 22px;
}
.blog-comment-item .comment-content .meta {
  color: #797e86;
  font-size: 14px;
  line-height: 29px;
}
.blog-comment-item .comment-content .meta .time {
  color: #5138ee;
}
.blog-comment-item .reply-btn {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  color: #5138ee;
  width: 110px;
  height: 45px;
  line-height: 43px;
}
.blog-comment-item .reply-btn:hover {
  background-color: #5138ee;
  border-color: #5138ee;
  color: #ffffff;
}
.blog-comment-item .reply-btn:hover i {
  color: #ffffff;
}
.blog-comment-item .reply-btn i {
  margin-right: 5px;
}
@media (max-width: 575px) {
  .blog-comment-item .reply-btn-wrap {
    margin-top: 10px;
  }
}
.blog-comment-item .feedback {
  color: #797e86;
  font-size: 15px;
  line-height: 25px;
  margin-top: 15px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .blog-comment-item .feedback {
    font-size: 14px;
  }
}
.blog-style-1 {
  padding-top: 140px;
}
.blog-style-1 .blog-item-wrap {
  --bs-gutter-y: 25px;
}
@media (min-width: 1400px) {
  .blog-style-1 .blog-item-wrap {
    --bs-gutter-x: 70px !important;
    --bs-gutter-y: 65px;
  }
}

/* ---Sidebar--- */
@media (max-width: 991px) {
  .sidebar-area {
    margin-top: 65px;
  }
}
.sidebar-title {
  color: #0a071b;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 25px;
}
.sidebar-searchbox {
  position: relative;
}
.sidebar-searchbox .input-field {
  background-color: #f8f7fc;
  border: 1px solid #f8f7fc;
  font-family: "Gordita";
  font-size: 15px;
  border-radius: 15px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 30px;
  padding-right: 55px;
}
.sidebar-searchbox .input-field::placeholder {
  color: #0a071b;
}
.sidebar-searchbox-btn {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding-right: 0;
}
.sidebar-searchbox-btn .search-icon-wrap {
  vertical-align: middle;
  position: relative;
}
.sidebar-searchbox-btn .search-icon-wrap:after {
  content: url("../images/sidebar/icon/search.png");
}
.sidebar-post {
  margin-top: 75px;
}
.sidebar-post .sidebar-title {
  margin-bottom: 40px;
}
.sidebar-post .post-list-slider {
  overflow: hidden;
}
.sidebar-post .post-list-item {
  display: flex;
}
.sidebar-post .post-list-img {
  width: 88px;
}
.sidebar-post .post-list-content {
  width: calc(100% - 102px);
  margin-left: 20px;
}
.sidebar-post .post-list-content .title {
  color: #2a2c39;
  font-size: 14px;
  line-height: 25px;
}
.sidebar-post .post-list-content .title a {
  color: #2a2c39;
}
.sidebar-post .post-list-content .meta span {
  font-family: "Gordita";
  color: #52565b;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
}
.sidebar-post .post-list-content .meta span.date i {
  margin-right: 5px;
  line-height: 28px;
}
.sidebar-post .post-list-content .meta .time {
  position: relative;
  padding-left: 15px;
  margin-left: 8px;
}
.sidebar-post .post-list-content .meta .time:before {
  background-color: #52565b;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.sidebar-social {
  margin-top: 70px;
}
.sidebar-social .sidebar-title {
  margin-bottom: 40px;
}
.sidebar-social .social-list li {
  background-color: #f8f7fc;
  border-radius: 15px;
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease 0s;
}
.sidebar-social .social-list li:not(:last-child) {
  margin-bottom: 20px;
}
.sidebar-social .social-list li:hover {
  background-color: #5138ee;
}
.sidebar-social .social-list li:hover * {
  color: #ffffff;
}
.sidebar-social .social-icon {
  font-family: "Gordita";
  color: #0a071b;
}
.sidebar-social .social-icon i {
  margin-right: 15px;
  font-size: 18px;
}
.sidebar-social .social-activity {
  font-family: "Gordita";
  color: #5138ee;
  font-size: 15px;
}
.sidebar-social .social-activity a {
  color: #5138ee;
}
.sidebar-banner {
  margin-top: 55px;
}
.sidebar-banner .sidebar-img {
  position: relative;
}
.sidebar-banner .sidebar-img:before {
  background-color: #0a071b;
  border-radius: 15px;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.75;
}
.sidebar-banner .sidebar-img img {
  border-radius: 15px;
  width: 100%;
}
.sidebar-banner .sidebar-img .inner-content {
  position: absolute;
  top: 115px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
.sidebar-banner .sidebar-img .inner-content .title {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}
.sidebar-banner .sidebar-img .inner-content .title span {
  font-weight: 500;
}
@media (min-width: 1400px) {
  .sidebar-style-1 {
    padding-left: 70px;
  }
}

/*----------------------------------------*/
/*  19. Form
/*----------------------------------------*/
.form .group-input {
  display: flex;
}
.form-field {
  width: 100%;
}
.form-field[class*=form-field]:not(:last-child) {
  margin-right: 30px;
}
.form-field .input-field,
.form-field .textarea-field {
  background-color: #fafafa;
  border: 1px solid #fafafa;
  border-radius: 10px;
  color: #52565b;
  font-size: 15px;
  padding: 25px;
  width: 100%;
  height: 55px;
}
.form-field .input-field:focus,
.form-field .textarea-field:focus {
  background-color: transparent;
  border-color: #efefef;
}
.form-field .textarea-field {
  margin-top: 30px;
  height: 285px;
}
.form-btn {
  font-family: "Gordita";
  background-color: #1a2c99;
  border: 1px solid #1a2c99;
  border-radius: 10px;
  font-weight: 500;
  width: 200px;
  height: 60px;
  line-height: 57px;
  color: #ffffff;
  font-size: 14px;
}
.form-btn:hover {
  background-color: #f0582e;
  border-color: #f0582e;
}
.form-btn i {
  vertical-align: text-bottom;
  margin-left: 10px;
  font-size: 20px;
}
.form-btn-wrap {
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
}
.form-area {
  padding-top: 50px;
}
@media (min-width: 992px) {
  .form-wrap {
    padding-left: 100px;
    padding-right: 100px;
  }
}
.form-title {
  color: #0a1e43;
  font-size: 25px;
  line-height: 40px;
  margin-bottom: 35px;
}
@media (min-width: 768px) {
  .form-style-2 .form-wrap {
    padding-left: 115px;
    padding-right: 115px;
  }
}
.form-style-2 .form-field {
  margin-bottom: 20px;
}
.form-style-2 .form-field[class*=form-field]:not(:last-child) {
  margin-right: 0;
}
.form-style-2 .form-field .input-field,
.form-style-2 .form-field .textarea-field {
  border-color: #efefef;
}
.form-style-2 .form-field .input-field:focus,
.form-style-2 .form-field .textarea-field:focus {
  background-color: transparent;
  border-color: #1c2fa7;
}
.form-style-2 .form-field .textarea-field {
  margin-top: 0;
}
.form-style-2 .form-btn {
  background-color: #f0582e;
  border-color: #f0582e;
}
.form-style-2 .form-btn:hover {
  background-color: #1a2c99;
  border: 1px solid #1a2c99;
}
.form-style-2 .form-btn i {
  vertical-align: text-bottom;
  margin-left: 10px;
  font-size: 20px;
}
.form-style-2 .form-btn-wrap {
  display: flex;
  align-items: flex-start;
  margin-top: 50px;
}
.form-style-2 .form-messege {
  margin-top: 25px;
  margin-bottom: 0;
}
.form-style-2 .form-messege.error {
  color: red;
}
.form-style-2 .form-messege.success {
  color: green;
}

/*----------------------------------------*/
/*  20. Contact
/*----------------------------------------*/
.contact-map {
  padding-top: 140px;
}
.contact-map-size {
  height: 500px;
  width: 100%;
}
@media (min-width: 992px) {
  .contact-sidebar {
    padding-left: 40px;
  }
}
.contact-sidebar .inner-img {
  position: relative;
}
.contact-sidebar-banner {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 30px;
}
.contact-sidebar-banner img {
  width: 100%;
}
.contact-sidebar-banner:hover .sidebar-btn {
  background-color: #f0582e;
}
.contact-sidebar .sidebar-btn {
  background-color: #1c2fa7;
  border-radius: 10px;
  width: 215px;
  height: 50px;
  line-height: 49px;
  font-size: 18px;
  display: block;
  text-align: center;
  color: #ffffff;
  position: relative;
}
.contact-sidebar .sidebar-btn:after {
  background-color: #ededed;
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  border-radius: 10px;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.3;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.contact-sidebar .sidebar-btn-wrap {
  transform: translateX(-50%);
  position: absolute;
  bottom: 20px;
  left: 50%;
}
.contact-sidebar-info {
  border: 1px solid #efefef;
  border-radius: 10px;
  padding-top: 30px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 30px;
  margin-top: 20px;
}
.contact-sidebar-info .contact-info li {
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;
}
.contact-sidebar-info .contact-info li:hover .contact-icon {
  background-color: #5138ee;
}
.contact-sidebar-info .contact-info li:hover .contact-icon img {
  filter: brightness(0) invert(1);
}
.contact-sidebar-info .contact-info li:not(:last-child) {
  margin-bottom: 10px;
}
.contact-sidebar-info .contact-info li a,
.contact-sidebar-info .contact-info li span {
  font-family: "Gordita";
  color: #52565b;
  font-size: 13px;
  line-height: 28px;
}
.contact-sidebar-info .contact-info li a:hover {
  color: #5138ee;
}
.contact-sidebar-info .contact-icon {
  background-color: #f4f4f4;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  margin-right: 15px;
  transition: all 0.3s ease 0s;
}
.contact-sidebar-info .contact-icon img {
  transition: all 0.3s ease 0s;
}
.contact-sidebar-info .social-link {
  margin-top: 20px;
}
.contact-sidebar-info .social-title {
  margin-bottom: 15px;
  color: #22262a;
  font-size: 18px;
}
.contact-sidebar-info .social-list {
  display: flex;
}
.contact-sidebar-info .social-list li:not(:last-child) {
  margin-right: 10px;
}
.contact-sidebar-info .social-list li a {
  background-color: #f4f4f4;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  display: block;
  transition: all 0.3s ease 0s;
}
.contact-sidebar-info .social-list li a:hover {
  background-color: #5138ee;
}
.contact-sidebar-info .social-list li a:hover i {
  color: #ffffff;
}
.contact-sidebar-info .social-list li a i {
  color: #22262a;
  font-size: 14px;
}

/*----------------------------------------*/
/*  21. 404
/*----------------------------------------*/
.error-404-area {
  padding-top: 140px;
}
.error-404-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.error-404-content {
  margin-top: 75px;
}
.error-404-content .title {
  color: #212832;
  font-size: 40px;
  line-height: 1.4;
  margin-bottom: 25px;
}
@media (max-width: 479px) {
  .error-404-content .title {
    font-size: 35px;
  }
}
.error-404-content .desc {
  font-family: "Gordita";
  color: #4a4b51;
  line-height: 30px;
  margin-bottom: 70px;
  max-width: 610px;
}