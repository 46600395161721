//--- All Variables - Related Stuff Included In This Area ---↓

// Font Family
$roboto-font: 'Roboto', sans-serif;
$playfair-font: 'Playfair Display', serif;
$gordita-font: 'Gordita';

// Color
$extbot-primary: #407ff0;
$extbot-primary-2: #fe6a21;
$extbot-primary-3: #25b760;
$extbot-primary-4: #ff8144;
$extbot-primary-5: #f0582e;
$extbot-secondary: #4a4b51;
$extbot-heading: #032659;
$extbot-heading-2: #2f2b54;
$white: #ffffff;

// Transition
$baseTransition: all 0.3s ease 0s;
