/*----------------------------------------*/
/*  03. Hero
/*----------------------------------------*/
.hero {
    &-style {
        &-1 {
            &.hero {
                &-bg {
                    height: 1070px;
                    @include breakpoint(max-xl-device) {
                        height: 950px;
                    }
                    @include breakpoint(max-lg-device) {
                        height: 850px;
                    }
                    @include breakpoint(max-md-device) {
                        height: auto;
                    }
                }
            }
            .hero {
                &-item {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    @include breakpoint(max-md-device) {
                        flex-direction: column;
                    }
                    .with-sticker {
                        display: flex;
                        .single-img {
                            box-shadow: 0 8px 20px 0 rgba(139, 245, 255, 0.1);
                            border-radius: 35px;
                            margin-left: -100px;
                            position: relative;
                            bottom: -40px;
                            @include breakpoint(max-xxs-device) {
                                margin-left: -70px;
                                bottom: -20px;
                            }
                        }
                    }
                }
                &-img {
                    &-wrap {
                        padding-left: 30px;
                        display: flex;
                        width: 50%;
                        @include breakpoint(max-md-device) {
                            padding-top: 30px;
                            padding-right: 30px;
                            width: 100%;
                        }
                    }
                    &.with-group {
                        align-self: center;
                        padding-left: 40px;
                    }
                }
                &-sticker {
                    background-color: #f9b047;
                    border-radius: 100%;
                    width: 140px;
                    height: 140px;
                    @include breakpoint(max-xxs-device) {
                        width: 85px;
                        height: 85px;
                    }
                    &.extbot-primary {
                        background-color: $extbot-primary;
                    }
                }
                &-content {
                    padding-left: 90px;
                    width: 50%;
                    @include breakpoint(max-xl-device) {
                        padding-left: 30px;
                        padding-right: 30px;
                    }
                    @include breakpoint(max-md-device) {
                        padding-top: 70px;
                        padding-bottom: 40px;
                        width: 100%;
                    }
                    .category {
                        font-family: $playfair-font;
                        font-weight: 700;
                        font-size: 25px;
                        color: #f9b047;
                        display: block;
                        margin-bottom: 20px;
                        @include breakpoint(max-sm-device) {
                            font-size: 20px;
                            margin-bottom: 10px;
                        }
                    }
                    .title {
                        max-width: 625px;
                        font-size: 95px;
                        line-height: 105px;
                        margin-bottom: 35px;
                        @include breakpoint(max-xl-device) {
                            font-size: 65px;
                            line-height: 1.4;
                        }
                        @include breakpoint(max-lg-device) {
                            font-size: 40px;
                            line-height: 1.4;
                        }
                        @include breakpoint(max-sm-device) {
                            font-size: 30px;
                            line-height: 1.4;
                            max-width: 350px;
                            margin-bottom: 15px;
                        }
                    }
                    .desc {
                        color: #57617b;
                        font-size: 25px;
                        line-height: 45px;
                        max-width: 625px;
                        margin-bottom: 50px;
                        @include breakpoint(max-sm-device) {
                            font-size: 18px;
                            line-height: 30px;
                            max-width: 380px;
                        }
                    }
                    .btn {
                        &-custom {
                            &-size {
                                @include breakpoint(max-sm-device) {
                                    width: 150px;
                                    height: 50px;
                                    line-height: 47px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-2 {
            &.hero {
                &-bg {
                    position: relative;
                    overflow: hidden;
                    height: 940px;
                    @include breakpoint(max-md-device) {
                        height: auto;
                    }
                }
            }
            .hero {
                &-container {
                    height: 100%;
                    @include breakpoint(min-xxl-device) {
                        max-width: 1500px;
                    }
                    @include breakpoint(xl-device) {
                        max-width: 100%;
                    }
                }
                &-item {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    height: 100%;
                }
                &-content {
                    width: calc(100% - 500px);
                    padding-right: 30px;
                    align-self: center;
                    @include breakpoint(max-md-device) {
                        width: calc(100% - 230px);
                    }
                    @include breakpoint(max-sm-device) {
                        width: 100%;
                        padding-bottom: 410px;
                        text-align: center;
                        padding-right: 0;
                    }
                    .title {
                        font-size: 75px;
                        line-height: 90px;
                        margin-top: 90px;
                        margin-bottom: 40px;
                        @include breakpoint(max-xl-device) {
                            font-size: 65px;
                            line-height: 85px;
                        }
                        @include breakpoint(max-lg-device) {
                            font-size: 40px;
                            line-height: 60px;
                        }
                        @include breakpoint(max-sm-device) {
                            font-size: 32px;
                            line-height: 40px;
                            margin-bottom: 20px;
                            margin-top: 50px;
                        }
                    }
                    .desc {
                        max-width: 750px;
                        font-size: 25px;
                        line-height: 45px;
                        margin-bottom: 40px;
                        @include breakpoint(max-xl-device) {
                            max-width: 400px;
                            font-size: 22px;
                        }
                        @include breakpoint(max-sm-device) {
                            max-width: 100%;
                            font-size: 16px;
                            margin-left: auto;
                            margin-right: auto;
                            line-height: 30px;
                        }
                    }
                    .btn {
                        &-custom {
                            &-size {
                                @include breakpoint(max-sm-device) {
                                    width: 190px;
                                    height: 60px;
                                    line-height: 57px;
                                    font-size: 14px;
                                }
                            }
                        }
                        &-wrap {
                            display: flex;
                            align-items: center;
                            @include breakpoint(max-md-device) {
                                margin-bottom: 90px;
                            }
                            @include breakpoint(max-sm-device) {
                                justify-content: center;
                            }
                            .popup-vimeo {
                                .icon {
                                    background-color: $white;
                                    color: $extbot-primary-2;
                                    border-radius: 50%;
                                    display: block;
                                    width: 70px;
                                    height: 70px;
                                    line-height: 67px;
                                    text-align: center;
                                    margin-left: 40px;
                                    position: relative;
                                    transition: $baseTransition;
                                    &:hover {
                                        background-color: #2f2b54;
                                        color: $white;
                                        span {
                                            border-left: solid 12px $white;
                                        }
                                    }
                                    @include breakpoint(max-sm-device) {
                                        width: 60px;
                                        height: 60px;
                                        line-height: 57px;
                                        margin-left: 20px;
                                    }
                                    span {
                                        width: 8px;
                                        height: 5px;
                                        border-left: solid 12px
                                            $extbot-primary-2;
                                        border-bottom: solid 8px transparent;
                                        border-top: solid 7px transparent;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }
                            }
                        }
                    }
                    &.white {
                        &-text {
                            * {
                                color: $white;
                            }
                        }
                    }
                }
                &-img {
                    position: absolute;
                    padding: 15px;
                    top: 160px;
                    right: 0;
                    z-index: 2;
                    width: 500px;
                    @include breakpoint(md-device) {
                        top: 50%;
                        width: 230px;
                        transform: translateY(-50%);
                    }
                    @include breakpoint(max-sm-device) {
                        top: calc(100% - 450px);
                        width: calc(100% - 30px);
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    &:before {
                        border: 2px solid rgb(255, 255, 255);
                        border-radius: 44px;
                        opacity: 0.349;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }
                &-sticker {
                    background-color: rgb(255, 255, 255);
                    transform: skewX(-34deg);
                    position: absolute;
                    width: 210px;
                    height: 100%;
                    top: -160px;
                    right: 0;
                    z-index: 1;
                    @include breakpoint(max-md-device) {
                        display: none;
                    }
                }
            }
        }
        &-3 {
            &.hero {
                &-bg {
                    height: 980px;
                    padding-bottom: 115px;
                    background-position: bottom;
                    @include breakpoint(max-sm-device) {
                        height: auto;
                    }
                }
            }
            .hero {
                &-container {
                    height: 100%;
                    @include breakpoint(min-xxl-device) {
                        max-width: 1500px;
                    }
                    @include breakpoint(xl-device) {
                        max-width: 100%;
                    }
                }
                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    @include breakpoint(max-xl-device) {
                        overflow: hidden;
                    }
                    @include breakpoint(max-sm-device) {
                        flex-direction: column-reverse;
                    }
                }
                &-content {
                    align-self: center;
                    @include breakpoint(min-xl-device) {
                        width: 685px;
                    }
                    @include breakpoint(md-device) {
                        width: 350px;
                    }
                    .title {
                        max-width: 650px;
                        font-size: 75px;
                        line-height: 84px;
                        margin-bottom: 40px;
                        @include breakpoint(max-xl-device) {
                            font-size: 55px;
                            line-height: 74px;
                        }
                        @include breakpoint(max-lg-device) {
                            font-size: 35px;
                            line-height: 54px;
                            margin-bottom: 40px;
                        }
                    }
                    .desc {
                        max-width: 615px;
                        font-size: 25px;
                        line-height: 45px;
                        margin-bottom: 40px;
                        @include breakpoint(max-lg-device) {
                            font-size: 20px;
                            line-height: 35px;
                        }
                    }
                    &.white {
                        &-text {
                            * {
                                color: $white;
                            }
                        }
                    }
                    .btn {
                        &-lg {
                            @include breakpoint(max-md-device) {
                                width: 160px;
                                height: 60px;
                                line-height: 57px;
                                font-size: 14px;
                            }
                        }
                        &-wrap {
                            .extbot {
                                &-link {
                                    margin-left: 45px;
                                    font-size: 18px;
                                    @include breakpoint(max-lg-device) {
                                        margin-left: 25px;
                                    }
                                    @include breakpoint(max-xxs-device) {
                                        margin-left: 0;
                                        display: block;
                                        margin-top: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
                &-img {
                    display: flex;
                    transform: rotate(-20deg);
                    @include breakpoint(min-xl-device) {
                        width: calc(100% - 685px);
                    }
                    @include breakpoint(md-device) {
                        justify-content: flex-end;
                        width: calc(100% - 350px);
                    }
                    @include breakpoint(max-sm-device) {
                        transform: none;
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }
                    [class*='single-img'] {
                        width: 38%;
                        height: 425px;
                        @include breakpoint(max-sm-device) {
                            height: auto;
                        }
                        &:not(:last-child) {
                            margin-right: 30px;
                            margin-top: 90px;
                        }
                    }
                    .single-img {
                        img {
                            box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
                            border-radius: 32px;
                        }
                    }
                }
            }
        }
        &-4 {
            .container {
                height: 100%;
            }
            &.hero {
                &-bg {
                    height: 1055px;
                    background-position: bottom;
                    @include breakpoint(max-md-device) {
                        height: 850px;
                    }
                    @include breakpoint(max-md-device) {
                        height: 750px;
                    }
                }
            }
            .hero {
                &-item {
                    height: 100%;
                }
                &-content {
                    padding-top: 165px;
                    text-align: center;
                    @include breakpoint(max-md-device) {
                        padding-top: 50px;
                        padding-bottom: 50px;
                    }
                    .title {
                        color: #500836;
                        font-size: 75px;
                        line-height: 95px;
                        margin-bottom: 30px;
                        @include breakpoint(max-md-device) {
                            font-size: 45px;
                            line-height: 65px;
                        }
                        @include breakpoint(max-sm-device) {
                            font-size: 35px;
                            line-height: 50px;
                        }
                        span {
                            position: relative;
                            z-index: 1;
                            &:before {
                                background-color: $extbot-primary-4;
                                border-radius: 50%;
                                width: 130px;
                                height: 130px;
                                content: '';
                                position: absolute;
                                top: 0;
                                left: -45px;
                                z-index: -1;
                            }
                        }
                    }
                    .desc {
                        color: #534e4b;
                        font-size: 25px;
                        line-height: 45px;
                    }
                }
                &-btn {
                    &-wrap {
                        a {
                            display: inline-flex;
                            &:not(:last-child) {
                                margin-right: 25px;
                            }
                        }
                    }
                }
            }
        }
        &-5 {
            &.hero {
                &-bg {
                    height: 1025px;
                    @include breakpoint(max-lg-device) {
                        height: 850px;
                    }
                    @include breakpoint(max-md-device) {
                        height: 750px;
                    }
                    @include breakpoint(max-sm-device) {
                        height: auto;
                    }
                }
            }
            .hero {
                &-container {
                    height: 100%;
                    @include breakpoint(min-xxl-device) {
                        max-width: 1500px;
                    }
                    @include breakpoint(xl-device) {
                        max-width: 100%;
                    }
                }
                &-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    position: relative;
                    z-index: 1;
                    @include breakpoint(max-sm-device) {
                        flex-direction: column;
                        padding-bottom: 110px;
                    }
                }
                &-content {
                    align-self: center;
                    @include breakpoint(max-sm-device) {
                        margin-bottom: 50px;
                        margin-top: 35px;
                        text-align: center;
                    }
                    .title {
                        max-width: 715px;
                        font-size: 75px;
                        line-height: 84px;
                        margin-bottom: 45px;
                        @include breakpoint(max-xl-device) {
                            font-size: 40px;
                            line-height: 60px;
                        }
                        @include breakpoint(max-md-device) {
                            font-size: 30px;
                            line-height: 50px;
                        }
                    }
                    .desc {
                        max-width: 715px;
                        font-size: 25px;
                        line-height: 45px;
                        margin-bottom: 40px;
                        @include breakpoint(max-xl-device) {
                            font-size: 16px;
                            line-height: 35px;
                        }
                    }
                    &.white {
                        &-text {
                            * {
                                color: $white;
                            }
                        }
                    }
                    .btn {
                        &-wrap {
                            .extbot {
                                &-link {
                                    margin-left: 45px;
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
                &-img {
                    padding-left: 30px;
                    position: relative;
                    left: -35px;
                    .single-img {
                        position: relative;
                        right: -40px;
                    }
                }
                &-sticker {
                    background-color: $extbot-primary-5;
                    border-radius: 50%;
                    width: 195px;
                    height: 195px;
                    &-wrap {
                        position: absolute;
                        bottom: -60px;
                        left: 0;
                        z-index: -1;
                        @include breakpoint(max-lg-device) {
                            left: 30px;
                        }
                    }
                }
            }
        }
    }
}
