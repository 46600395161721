/*----------------------------------------*/
/*  08. Pricing
/*----------------------------------------*/
.pricing {
    &-area {
        padding-top: 115px;
        padding-bottom: 140px;
        .section {
            &-title {
                margin-bottom: 20px;
            }
        }
    }
    &-tab {
        margin-bottom: 80px;
        text-align: center;
        &-nav {
            background-color: #f4fbf7;
            display: inline-flex;
            border-radius: 24px;
            justify-content: center;
            li {
                a {
                    border-radius: 24px;
                    padding-top: 10px;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-bottom: 10px;
                    display: block;
                    font-size: 18px;
                    color: #1f1927;
                    font-weight: 500;
                }
            }
        }
    }
    &-item {
        border: 2px solid #eaeff4;
        transition: $baseTransition;
        border-radius: 30px;
        text-align: center;
        padding-top: 50px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 50px;
        &:hover,
        &.active {
            border-color: $extbot-primary;
            .pricing {
                &-info {
                    background-color: $extbot-primary;
                    span {
                        color: $white;
                    }
                }
                &-btn {
                    &-wrap {
                        .btn {
                            &-custom {
                                &-size {
                                    background-color: $extbot-primary;
                                    border-color: $extbot-primary;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-info {
        background-color: #f5f8fb;
        text-align: center;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
        span {
            display: block;
            line-height: 1;
            &.price {
                margin-bottom: 10px;
                color: #042659;
                font-weight: 700;
                font-size: 50px;
            }
            &.month {
                margin-left: 15px;
            }
        }
    }
    &-title {
        margin-bottom: 30px;
        font-size: 33px;
        @include breakpoint(max-lg-device) {
            font-size: 30px;
        }
    }
    &-desc {
        border-bottom: 1px solid #eaeff4;
        color: #57617b;
        font-size: 18px;
        line-height: 32px;
        margin-bottom: 35px;
        padding-bottom: 35px;
    }
    &-list {
        li {
            a {
                color: #38475e;
                font-weight: 500;
                font-size: 20px;
                line-height: 48px;
                &.line-through {
                    text-decoration: line-through;
                    color: #adb8c7;
                }
            }
        }
    }
    &-btn {
        &-wrap {
            margin-top: 35px;
        }
    }
    &-style {
        &-2 {
            .section {
                &-title {
                    color: $extbot-heading-2;
                    font-size: 55px;
                    line-height: 70px;
                    @include breakpoint(max-sm-device) {
                        font-size: 35px;
                        line-height: 50px;
                    }
                }
                &-desc {
                    line-height: 42px;
                }
            }
            .pricing {
                &-tab {
                    &-nav {
                        background-color: #f8f3f0;
                        li {
                            a {
                                font-family: $gordita-font;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                &.active {
                                    background-color: $extbot-primary-2;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
                &-item {
                    border: 1px solid #f4eeea;
                    text-align: left;
                    padding-top: 70px;
                    @include breakpoint(max-md-device) {
                        margin-bottom: 50px;
                    }
                    &:hover {
                        background-color: #2f2b54;
                        border-color: #2f2b54;
                        .pricing {
                            &-wrap {
                                &:before {
                                    background-color: rgb(234, 239, 244);
                                    opacity: 0.071;
                                }
                            }
                            &-title {
                                color: $white;
                            }
                            &-desc {
                                color: $white;
                            }
                            &-info {
                                background-color: transparent;
                                &:before {
                                    border-radius: 25px;
                                    opacity: 0.05;
                                    visibility: visible;
                                }
                                span {
                                    &.price,
                                    &.month {
                                        color: $white;
                                    }
                                }
                            }
                            &-list {
                                &-wrap {
                                    li {
                                        a {
                                            color: $white;
                                        }
                                    }
                                    .btn {
                                        &-outline {
                                            background-color: $extbot-primary-2;
                                            border-color: $extbot-primary-2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &-wrap {
                        @include breakpoint(min-xl-device) {
                            --bs-gutter-x: 70px;
                        }
                    }
                }
                &-wrap {
                    position: relative;
                    margin-bottom: 45px;
                    padding-bottom: 55px;
                    display: flex;
                    align-items: center;
                    @include breakpoint(max-xxs-device) {
                        flex-direction: column;
                    }
                    &:before {
                        background-color: #f4eeea;
                        height: 2px;
                        width: 100%;
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        transition: $baseTransition;
                    }
                    .title-with-desc {
                        width: calc(100% - 150px);
                        padding-left: 50px;
                        @include breakpoint(max-xxs-device) {
                            padding-top: 25px;
                            padding-left: 0;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
                &-info {
                    background-color: #f8f3f0;
                    border-radius: 25px;
                    margin-bottom: 0;
                    width: 150px;
                    align-self: center;
                    position: relative;
                    &:before {
                        background-color: $white;
                        width: 100%;
                        height: 100%;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: $baseTransition;
                    }
                }
                &-title {
                    margin-bottom: 20px;
                }
                &-desc {
                    border-bottom: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
                &-list {
                    &.style {
                        &-2 {
                            li {
                                a {
                                    line-height: 58px;
                                }
                            }
                            li {
                                a {
                                    color: #57617b;
                                    font-size: 19px;
                                    font-weight: 400;
                                    @include breakpoint(max-sm-device) {
                                        line-height: 40px;
                                        font-size: 18px;
                                        display: flex;
                                        align-items: baseline;
                                    }
                                    i {
                                        color: $extbot-primary-2;
                                        margin-right: 10px;
                                        font-size: 23px;
                                    }
                                }
                            }
                            .pricing {
                                &-list {
                                    &-wrap {
                                        padding-left: 30px;
                                        padding-right: 30px;
                                    }
                                }
                            }
                        }
                    }
                    &-wrap {
                        padding-left: 30px;
                        padding-right: 30px;
                        @include breakpoint(max-xxs-device) {
                            padding-left: 0;
                            padding-right: 0;
                        }
                        .pricing {
                            &-btn-wrap {
                                margin-top: 40px;
                            }
                        }
                        .btn {
                            &-outline {
                                border: 2px solid #f8f3f0;
                                background-color: #f8f3f0;
                            }
                            i {
                                margin-left: 20px;
                            }
                        }
                    }
                }
            }
        }
        &-3 {
            .section {
                &-title {
                    color: #1f1927;
                    font-size: 55px;
                    line-height: 70px;
                    @include breakpoint(max-sm-device) {
                        font-size: 35px;
                        line-height: 50px;
                    }
                }
                &-desc {
                    color: #6f7872;
                    line-height: 42px;
                    margin-bottom: 70px;
                }
            }
            .pricing {
                &-tab {
                    &-nav {
                        li {
                            a {
                                font-family: $gordita-font;
                                &.active {
                                    background-color: $extbot-primary-3;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
                &-item {
                    transition: $baseTransition;
                    transition-delay: 0.02s;
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    @include breakpoint(max-md-device) {
                        margin-bottom: 30px;
                    }
                    &:before {
                        background-color: #26212c;
                        border-radius: 0 0 50% 50%;
                        width: 370px;
                        height: 222px;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(-30px);
                        transition: $baseTransition;
                    }
                    &:after {
                        border: 1px solid #342e3b;
                        border-radius: 0 0 50% 50%;
                        position: absolute;
                        content: '';
                        top: 0;
                        left: -10px;
                        width: 390px;
                        height: 242px;
                        z-index: -1;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(-30px);
                        transition: $baseTransition;
                    }
                    &:hover {
                        background-color: #1f1927;
                        border-color: #1f1927;
                        &:before {
                            transform: translateY(0);
                            opacity: 1;
                            visibility: visible;
                        }
                        &:after {
                            transform: translateY(0);
                            opacity: 1;
                            visibility: visible;
                        }
                        .pricing {
                            &-info {
                                background-color: $extbot-primary-3;
                            }
                            &-title {
                                color: $white;
                            }
                            &-list {
                                li {
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                            &-btn {
                                &-wrap {
                                    .btn {
                                        &-custom {
                                            &-size {
                                                background-color: $extbot-primary-3;
                                                border-color: $extbot-primary-3;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-info {
                    background-color: $extbot-primary-3;
                    border-radius: 30px;
                    span {
                        color: $white;
                    }
                }
                &-list {
                    li {
                        a {
                            color: #666f69;
                        }
                    }
                }
                &-btn {
                    &-wrap {
                        .btn {
                            &-custom {
                                &-size {
                                    background-color: #f4fbf7;
                                    color: $extbot-primary-3;
                                    border-color: #f4fbf7;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-4 {
            .section {
                &-title {
                    color: #500836;
                    font-size: 55px;
                    line-height: 70px;
                    @include breakpoint(max-sm-device) {
                        font-size: 35px;
                        line-height: 50px;
                    }
                }
                &-desc {
                    color: #534e4b;
                    line-height: 42px;
                }
            }
            .pricing {
                &-tab {
                    &-nav {
                        background-color: #ffe4df;
                        li {
                            a {
                                font-family: $gordita-font;
                                &.active {
                                    background-color: $extbot-primary-4;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
                &-item {
                    position: relative;
                    overflow: hidden;
                    padding-top: 15px;
                    padding-left: 15px;
                    border-color: #f6f1ee;
                    transition: $baseTransition;
                    @include breakpoint(max-md-device) {
                        margin-bottom: 30px;
                    }
                    &:hover {
                        border-color: $extbot-primary-4;
                        background-color: $extbot-primary-4;
                        transform: translateY(-25px);
                        &:before {
                            filter: opacity(0.3);
                        }
                        .pricing {
                            &-info {
                                background-color: $white;
                                .price {
                                    color: #500836;
                                }
                                .month {
                                    color: #534e4b;
                                }
                            }
                            &-title {
                                color: $white;
                            }
                            &-list {
                                li {
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                            &-btn {
                                &-wrap {
                                    .btn {
                                        &-custom {
                                            &-size {
                                                background-color: $white;
                                                color: $extbot-primary-4;
                                                border-color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:before {
                        content: url('../images/testimonial/shape/1.png');
                        position: absolute;
                        top: -40px;
                        right: -35px;
                        filter: opacity(1);
                        transition: $baseTransition;
                        z-index: -1;
                    }
                }
                &-info {
                    margin-left: 0;
                    border-radius: 30px 10px 145px 10px;
                    width: 167.5px;
                    height: 167.5px;
                    background-color: $extbot-primary-4;
                    align-items: flex-start;
                    span {
                        color: $white;
                        &.price {
                            margin-left: 30px;
                        }
                        &.month {
                            margin-left: 60px;
                        }
                    }
                }
                &-btn {
                    &-wrap {
                        .btn {
                            &-custom {
                                &-size {
                                    background-color: #ffe4df;
                                    color: $extbot-primary-4;
                                    border-color: #ffe4df;
                                }
                            }
                        }
                    }
                }
            }
        }
        &-5 {
            .section {
                &-title {
                    color: #07135b;
                    font-size: 55px;
                    line-height: 70px;
                    @include breakpoint(max-md-device) {
                        font-size: 35px;
                        line-height: 50px;
                    }
                }
                &-desc {
                    margin-bottom: 65px;
                    color: #534e4b;
                }
            }
            .pricing {
                &-tab {
                    &-nav {
                        background-color: #f4f5ff;
                        li {
                            a {
                                &.active {
                                    background-color: #1a2c99;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
                &-item {
                    padding-top: 0;
                    overflow: hidden;
                    @include breakpoint(max-md-device) {
                        margin-top: 30px;
                    }
                    &:hover {
                        background-color: #1a2c99;
                        border-color: #1a2c99;
                        .pricing {
                            &-info {
                                background-color: transparent;
                                &:before {
                                    border-bottom: 135px solid $white;
                                }
                                &:after {
                                    border-bottom: 155px solid #2738a2;
                                }
                                span {
                                    color: #07135b;
                                }
                            }
                            &-title {
                                color: $white;
                            }
                            &-list {
                                li {
                                    a {
                                        color: $white;
                                    }
                                }
                            }
                            &-btn {
                                &-wrap {
                                    .btn {
                                        &-custom {
                                            &-size {
                                                background-color: $extbot-primary-5;
                                                border-color: $extbot-primary-5;
                                                color: $white;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &-info {
                    background-color: transparent;
                    position: relative;
                    z-index: 1;
                    &:before {
                        border-bottom: 135px solid #1a2c99;
                        border-left: 100px solid transparent;
                        border-right: 100px solid transparent;
                        height: 0;
                        width: 275px;
                        content: '';
                        position: absolute;
                        border-radius: 0 0 50% 50%;
                        z-index: 1;
                        bottom: 15px;
                        transition: $baseTransition;
                    }
                    &:after {
                        border-bottom: 155px solid #f4f5ff;
                        border-left: 105px solid transparent;
                        border-right: 105px solid transparent;
                        height: 0;
                        width: 330px;
                        content: '';
                        position: absolute;
                        border-radius: 0 0 50% 50%;
                        z-index: -1;
                        bottom: 0;
                        transition: $baseTransition;
                    }
                    span {
                        color: $white;
                        z-index: 2;
                    }
                }
                &-btn {
                    &-wrap {
                        .btn {
                            &-custom {
                                &-size {
                                    background-color: #f4f5ff;
                                    border-color: #f4f5ff;
                                    color: $extbot-primary-5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
